import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "@redux/hooks";
import BaseTable from "@reusables/BaseTable";
import { normalizePrice, usePagination } from "@helpers/utils";
import Author from "@components/Dashboard/pages/Products/components/Tabs/items/PricesTab/components/Author";
import { useGetProductPurchasePricesQuery } from "@redux/features/products/productsApi";
import { Pagination } from "@mui/material";
import OriginLink from "@components/Dashboard/pages/Products/components/Tabs/items/PricesTab/components/OriginLink";

export default function PurchasePricesTable({ product_id }: { product_id: number }) {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct.tabs.prices" });

    const baseCurrency = useAppSelector(state => state.auth.user?.company.currency);

    const pagination = usePagination({ page: 1, limit: 8 });

    const { data: purchasePrices, isLoading: isPurchasePricesLoading } = useGetProductPurchasePricesQuery({
        product_id,
        pagination
    });

    return (
        <div className={"flex flex-column border border-solid rounded-[8px] p-[8px]"}>
            <div className={"grow"}>
                <BaseTable
                    isDataLoading={isPurchasePricesLoading}
                    data={purchasePrices?.payload ?? []}
                    columns={[
                        {
                            header: t("table.columns.purchasingPrice"), //TODO: @NEKEAR CHECK how the price gets displayed - maybe adjust something? Also there seems to be no info about the second currency (that we make a conversion to) in product model
                            getter: (row) => (
                                <>
                                        <span>
                                            {baseCurrency?.code} {normalizePrice(row.price)}
                                        </span>
                                    {" "}
                                    {
                                        !!row.currency && row.currency.id != baseCurrency?.id &&
                                        <span className="text-[#B1B2E1]">
                                                ({row.currency?.symbol} {normalizePrice(row.price * (row.currency?.rate ?? 1))})
                                            </span>
                                    }
                                </>
                            )
                        },
                        {
                            header: t("table.columns.date"),
                            getter: (row) => <>{row.date.format("DD.MM.YYYY HH:mm")}</>
                        },
                        {
                            header: t("table.columns.author"),
                            getter: (row) => {
                                if (row.author) {
                                    return <Author {...row.author} />;
                                }

                                return <span className={"text-[#B1B2E1]"}>
                                        -
                                    </span>;
                            }
                        },
                        {
                            header: t("table.columns.origin"),
                            getter: (row) => <OriginLink origin={row.origin} />
                        }
                    ]}
                />
            </div>
            <Pagination
                className="mt-[32px]"
                {...pagination.adapt(purchasePrices)}
            />
        </div>
    );
}