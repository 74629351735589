import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import ViewLayout from "../components/ViewLayout";
import ProductStepper from "../components/Tabs/ProductStepper";
import { useGetProductQuery } from "@redux/features/products/productsApi";
import { Can } from "@/casl.config";
import { BaseTinyActionIcon, TinyActionIconType } from "@reusables/BaseTinyActionIcon";
import BaseRecordRenderer from "@reusables/BaseRecordRenderer";
import BaseBadge from "@reusables/BaseBadge";
import { ExternalLink, Forklift } from "lucide-react";
import DeletionModal from "@components/Dashboard/pages/Products/components/modals/DeletionModal";
import BarcodeLabelModal from "@components/Dashboard/pages/Products/components/modals/BarcodeLabelModal";

export default function ProductViewPage() {
    const { t } = useTranslation("", { keyPrefix: "products.viewEditProduct" });
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
    const [isBarcodeLabelModalOpen, setIsBarcodeLabelModalOpen] = useState(false);

    const { data: product, isLoading: productLoading } = useGetProductQuery(parseInt(id));

    return (
        <>
            <BaseRecordRenderer loading={productLoading} record={product}>
                <div className="space-y-[40px]">
                    <div className="flex mb-[40px]">
                        <div className={"flex flex-row items-center grow space-x-[8px]"}>
                            <div className="flex items-center text-2xl font-bold text-accent">{product?.name}</div>
                            {product?.is_service && (
                                <BaseBadge>
                                    <Forklift size={12} className={"mr-1"} />
                                    <span>{t("isService")}</span>
                                </BaseBadge>
                            )}
                            {!!product?.parent && (
                                <BaseBadge
                                    className="mx-2 bg-badge-blue cursor-pointer border-1 border-solid border-transparent hover:border-purple-400"
                                    onClick={() => history.push(`/dashboard/products/${product.parent!.id}/details`)}
                                >
                                    <ExternalLink size={12} className={"mr-1"} />
                                    <span>{t("isVariant")}</span>
                                </BaseBadge>
                            )}
                        </div>
                        <div className="space-x-[8px] flex items-center h-full">
                            <Can I="update" a="product">
                                <BaseTinyActionIcon type={TinyActionIconType.Edit}
                                                    onClick={() => history.push(`/dashboard/products/${id}/edit`)} />
                            </Can>

                            <Can I="delete" a="product">
                                <BaseTinyActionIcon type={TinyActionIconType.Delete}
                                                    onClick={() => setIsDeletionModalOpen(true)} />
                            </Can>

                            <BaseTinyActionIcon type={TinyActionIconType.Barcode}
                                                onClick={() => setIsBarcodeLabelModalOpen(true)} />
                        </div>
                    </div>
                    <ProductStepper informationTab={<ViewLayout product={product} />} product={product}
                                    previewMode={true} />
                </div>
            </BaseRecordRenderer>

            {/* DELETION MODAL */}
            <DeletionModal
                isOpen={isDeletionModalOpen}
                onClose={() => setIsDeletionModalOpen(false)}
                entity={product}
                onDeletion={() => {
                    history.push("/dashboard/products");
                }}
            />

            {/* BARCODE LABEL MODAL */}
            <BarcodeLabelModal
                isOpen={isBarcodeLabelModalOpen}
                onClose={() => setIsBarcodeLabelModalOpen(false)}
                product={product}
            />
        </>
    );
}
