import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import React, { Context, Dispatch, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FetchArrayConfig, PaginationWrapper, RequestConfig } from "@/types/types";
import caxios from "../config";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector, useStore } from "react-redux";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import {
    analyzeInput,
    cleanErrors,
    deregisterInput,
    registerInput,
    ValidationStoreState
} from "@reusables/Validator/validationStore";
import { Constraint, ConstraintSupport } from "@reusables/Validator/types";
import { BackendReadyOrdering } from "@reusables/BaseTable/types";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Integration, Location } from "@/types/general";
import { LocationOption } from "@components/Dashboard/pages/Inventory/Adjustments/logic";
import { PaginationResponse } from "@redux/api/internalApiSlice";
import _ from "lodash";
import { DatepickerRange } from "@reusables/BaseDatepickerLegacy/types";
import i18nInstance from "@/i18n";
import i18n from "@/i18n";
import laravelEcho from "@/config/laravelEcho";
import { Channel } from "laravel-echo";
import { isErrorWithMessage } from "@redux/api/query";
import { toast } from "react-toastify";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const COUNTRY_FLAGS_URL = import.meta.env.VITE_COUNTRY_FLAGS_API_URL;
const ORDER_PRICE_PRECISION = import.meta.env.VITE_ORDER_PRICE_PRECISION;

/**
 * Adapter for transient properties used in styled components (MUI utility).
 * Read more about {@link https://stackoverflow.com/questions/69730364/what-is-the-purpose-of-shouldforwardprop-option-in-styled shouldForwardProp}
 * and about {@link https://styled-components.com/docs/api#transient-props transient props}.
 * @api Prefix transient variables with underscore.
 */
export const TransientAdapter = { shouldForwardProp: (prop: PropertyKey) => prop.toString().substring(0, 1) !== "_" };

/**
 * Utility for parsing (in-place) all occurrences of a specific date field to a dayjs object.
 * Used as axios interceptor.
 * @param body The object to modify
 * @param target The field name to be parsed
 */
export function parseDate(body: any, target: string): void {
    if (body === null || body === undefined || typeof body !== "object") {
        return;
    }

    // If it's an array, iterate through it and apply the same function to each object
    if (Array.isArray(body)) {
        for (const item of body) {
            parseDate(item, target);
        }
        return;
    }

    // Loop through all keys in the object
    for (const key of Object.keys(body)) {
        // If the key matches the target field, try to parse it
        if (key === target) {
            const value: any = body[key];
            // Workaround for the fact that dayjs.utc() doesn't handle the "YYYY-MM-DD" format correctly
            const parsedDate = dayjs.utc(
                dayjs(value, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"])
                    .format("YYYY-MM-DD HH:mm:ss"),
                "YYYY-MM-DD HH:mm:ss"
            ).local();
            if (parsedDate.isValid()) {
                body[key] = parsedDate;
            }
        }

        // If the value is an object or an array, recurse into it
        if (typeof body[key] === "object") {
            parseDate(body[key], target);
        }
    }
}

/**
 * Swaps the values of two properties in an object.
 *
 * @param obj - The object containing the properties to swap.
 * @param key1 - The first property key.
 * @param key2 - The second property key.
 *
 * @example
 * const person = { firstName: 'John', lastName: 'Doe' };
 * swap(person, 'firstName', 'lastName');
 * // person: { firstName: 'Doe', lastName: 'John' }
 */
export function swap<T>(obj: T, key1: keyof T, key2: keyof T) {
    [obj[key1], obj[key2]] = [obj[key2], obj[key1]];
}

/**
 * Utility to create hooks, needed for pagination, at once. Allows different components accept result of this function as pagination configuration
 * and makes it easier to configure them.
 */
export function createPagination(): PaginationWrapper {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(0);
    const [elementsPerPage, setElementsPerPage] = useState<number>(8);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        setPages(Math.ceil((total / elementsPerPage)));
    }, [total]);

    return {
        page: { currentPage, setCurrentPage },
        count: { pages, setPages },
        perPage: { elementsPerPage, setElementsPerPage },
        total: { total, setTotal },
        calculate: (localTotal) => {
            const lengthOfTotal = Array.isArray(localTotal) ? localTotal.length : localTotal;
            setTotal(lengthOfTotal);
            if (lengthOfTotal != total) {
                setCurrentPage(1);
            }
        }
    };
}

export function usePagination({
                                  page = 1,
                                  limit = 8
                              }: {
    page?: number,
    limit?: number
}) {
    const [pageL, setPageL] = useState<number>(1);
    const [limitL, setLimitL] = useState<number>(8);

    useEffect(() => setPageL(page), [page]);
    useEffect(() => setLimitL(limit), [limit]);

    return {
        page: pageL,
        setPage: setPageL,
        limit: limitL,
        setLimit: setLimitL,
        adapt: (total?: PaginationResponse<any> | number) => {
            let count = 0;

            if (typeof total === "number") {
                count = Math.ceil(total / limitL);
            } else if (total) {
                count = Math.ceil(total.meta.total / limitL);
            }

            return {
                page: pageL,
                count: count,
                onChange: (e: React.ChangeEvent<unknown>, value: number) => setPageL(value),
                disabled: count === 0
            };
        }
    };
}

export function useOrdering<T extends string>(defaultValue?: BackendReadyOrdering<T>) {
    const [orderBy, setOrderBy] = useState<BackendReadyOrdering<T>[]>([]);

    const setOrderByProxy = (value?: BackendReadyOrdering<T>) => {
        setOrderBy(value ? [value] : []);
    };

    useEffect(() => {
        if (defaultValue)
            setOrderBy([defaultValue]);
    }, []);

    return {
        orderBy,
        setOrderBy: (value?: BackendReadyOrdering<T>) => {
            setOrderByProxy(value ?? defaultValue);
        }
    };
}

/**
 * Helper function, which returns true if any of criteria includes incoming string.
 * <ul>
 * <li>If criteria is <strong><u>string</u></strong> => in lowercase;</li>
 * <li>If criteria is <strong><u>number</u></strong> => after rounding two 2 digits, and commas in the incoming string will be replaced with dots;</li>
 * <li>If criteria is <strong><u>dayjs</u></strong> object => using isSame method, defining two date formats with >month< between year and day.</li>
 * </ul>
 * @param str
 * @param args
 */
export function anyIncludes(str: string, ...args: (string | number | dayjs.Dayjs)[]): boolean {

    for (let i = 0; i < args.length; i++) {
        const el = args[i];

        if (typeof el === "string") {
            if (el.toLowerCase().includes(str.toLowerCase()))
                return true;
        } else if (typeof el === "number") {
            if (el.toFixed(2).includes(str.replaceAll(",", ".")))
                return true;
        } else if (el.format("YYYY-MM-DD").includes(str) || dayjs(str, ["YYYY-MM-DD", "DD-MM-YYYY"]).isSame(el, "day")) {
            return true;
        }
    }

    return false;
}

/**
 * Filters an object's properties based on their boolean values and returns an array of keys with true values.
 *
 * @param rels - An object with properties containing boolean or undefined values.
 * @returns An array of keys with true values.
 *
 * @example
 * const input = { a: true, b: false, c: undefined, d: true };
 * const output = filterBooleans(input); // ['a', 'd']
 */
export function filterBooleans<T>(rels: Record<string, boolean | undefined>): string[] {
    return Object.entries(rels).filter(item => !!item[1]).map(item => item[0]);
}

export function fetchArrayReactively<RES, OG = any>({
                                                        route,
                                                        parseDates,
                                                        transform,
                                                        debug,
                                                        params,
                                                        mock
                                                    }: FetchArrayConfig<RES, OG>) {
    const token = localStorage.getItem("sanctum");

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<RES[]>([]);
    const pagination = createPagination();

    const func = async (localRoute?: string) => {
        if (mock) {
            setData(mock);
            pagination.calculate(mock.length);
            return;
        }

        setLoading(true);

        localRoute = localRoute ?? route;

        if (!localRoute) {
            console.error("No route specified for fetchArrayReactively!");
            return;
        }

        if (token) {
            caxios.get(localRoute, {
                headers: {
                    "X-App-Locale": i18nInstance.language,
                    "Authorization": "Bearer " + token
                },
                params: params,
                parseDates: parseDates
            }).then((res) => {
                if (debug)
                    console.log(dayjs().format("HH:mm:ss"), res);

                if ("payload" in res.data) {
                    setData(transform?.(res) ?? res.data.payload);
                } else { // might be deleted, when Yevhenii make everything have their data in the "payload" field
                    setData(transform?.(res) ?? res.data);
                }

                if ("meta" in res.data && "total" in res.data.meta) {
                    pagination.calculate(res.data.meta.total);
                } else {
                    if (data.length)
                        pagination.calculate(data.length);
                }
            }).catch((err) => {
                //console.log(err)
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        if (route)
            func(route);
    }, []);

    return {
        loading,
        data,
        // If setData is used outside of the component, pagination should be recalculated manually
        setData,
        pagination,
        call: func
    };
}

export function fetchArrayServerside<FinalType, Payload = any>({
                                                                   route,
                                                                   parseDates,
                                                                   transform,
                                                                   debug,
                                                                   params,
                                                                   filters,
                                                                   orderBy,
                                                                   dynamicRefetch,
                                                                   mock
                                                               }: FetchArrayConfig<FinalType, Payload> & {
    filters?: any,
    orderBy?: BackendReadyOrdering[]
} & { dynamicRefetch?: boolean }) {
    const token = localStorage.getItem("sanctum");

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<FinalType[]>([]);
    const pagination = createPagination();

    const func = async (localRoute?: string) => {
        if (mock) {
            setData(mock);
            pagination.calculate(mock.length);
            return;
        }

        setLoading(true);

        localRoute = localRoute ?? route;

        if (!localRoute)
            console.error("No route specified for fetchArrayServerside!");

        if (token && localRoute) {
            caxios.get(localRoute, {
                headers: {
                    "X-App-Locale": i18nInstance.language,
                    "Authorization": "Bearer " + token
                },
                params: {
                    reactive: 0,
                    ...params,

                    ...(pagination && {
                        pagination: {
                            itemsPerPage: pagination.perPage.elementsPerPage,
                            currentPage: pagination.page.currentPage
                        }
                    }),

                    ...(orderBy && {
                        orderBy: orderBy
                    }),

                    ...(filters && {
                        filters: filters
                    })
                },
                parseDates: parseDates
            }).then((res) => {
                if (debug)
                    console.log(dayjs().format("HH:mm:ss"), res);

                setData(transform?.(res) ?? res.data.payload);
                pagination.calculate(res.data.meta.total);

            }).catch((err) => {
                //console.log(err)
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    if (dynamicRefetch) {
        useEffect(() => {
            if (route)
                func(route);
        }, [filters, pagination.perPage.elementsPerPage, pagination.page.currentPage, orderBy]);
    } else {
        useEffect(() => {
            if (route)
                func(route);
        }, []);
    }

    return {
        loading,
        data,
        pagination,
        call: func
    };
}

export function fetchEntity<T>(props = {} as { route?: string, parseDates?: string[], mock?: T }) {
    const token = localStorage.getItem("sanctum");

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T>();

    const func = async (localRoute: string) => {
        if (props.mock) {
            setData(props.mock);
            return;
        }

        setLoading(true);

        localRoute = localRoute ?? props.route;

        if (!localRoute)
            console.error("No route specified for fetchEntity!");

        if (token) {
            caxios.get(localRoute, {
                headers: {
                    "X-App-Locale": i18nInstance.language,
                    "Authorization": "Bearer " + token
                },
                parseDates: props.parseDates
            }).then((res) => {
                console.log(res.data);
                if ("payload" in res.data) {
                    setData(res.data.payload);
                } else {
                    setData(res.data);
                }
            }).catch((err) => {
                //console.log(err)
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        if (props.route)
            func(props.route);
    }, []);

    return {
        loading,
        data,
        setData,
        call: func
    };
}

export function fetchExternalArray<T>(route?: string, extractData?: (resp: any) => T[], additionalResponseActions?: (resp: any) => void) {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T[]>([]);

    const func = async (localRoute: string) => {
        setLoading(true);

        localRoute = localRoute ?? route;

        if (!localRoute)
            console.error("No route specified for fetchExternalArray!");

        axios.get(localRoute).then((res) => {
            setData(extractData?.(res) ?? res.data);

            additionalResponseActions?.(res);
        }).catch((err) => {
            //console.log(err)
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (route)
            func(route);
    }, []);

    return {
        loading,
        data,
        call: func,
        setData,
        setLoading
    };
}

export function fetchExternalEntity<T>(route?: string) {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T>();

    const func = async (localRoute: string) => {
        setLoading(true);

        localRoute = localRoute ?? route;

        if (!localRoute)
            console.error("No route specified for fetchExternalArray!");

        axios.get(localRoute).then((res) => {
            setData(res.data);
        }).catch((err) => {
            //console.log(err)
        }).finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (route)
            func(route);
    }, []);

    return {
        loading,
        data,
        call: func
    };
}

/**
 * Performs an HTTP request with the given configuration, handling loading state and authentication.
 *
 * @param props
 *      - An object containing the request configuration:
 *
 *      - method: The HTTP method (e.g., 'GET', 'POST', 'PUT', 'DELETE').
 *
 *      - route: The URL of the request.
 *
 *      - body: The request payload (optional).
 *
 *      - responseType: The expected response type, such as 'json', 'arraybuffer', 'blob', or 'text' (optional).
 *
 *      - onLoadingChange: A callback function that receipts the loading state (optional).
 *
 *      - then: A callback function to handle a successful response.
 *
 *      - catch: A callback function to handle an error response (optional).
 *
 *      - finally: A callback function to execute after the request is completed, regardless of the outcome (optional).
 *
 * @example
 * manualRequest({
 *   method: 'GET',
 *   route: '/api/some-data',
 *   onLoadingChange: (isLoading) => console.log(`Loading: ${isLoading}`),
 *   then: (response) => console.log('Data received:', response.data),
 *   catch: (error) => console.error('Error fetching data:', error),
 *   finally: () => console.log('Request completed'),
 * });
 */
export function manualRequest<T = Record<string, any>>(props: RequestConfig<T>) {

    props.onLoadingChange?.(true);

    caxios({
        method: props.method,
        url: props.route,
        headers: {
            "X-App-Locale": i18nInstance.language,
            "Authorization": "Bearer " + localStorage.getItem("sanctum")
        },
        data: props.body,

        ...(!!props.responseType && {
            responseType: props.responseType
        })

    }).then(props.then).catch(props.catch).finally(() => {
        props.onLoadingChange?.(false);
        props.finally?.();
    });
}

/**
 * Custom hook for form input validation based on constraints.
 *
 * @param constraints - An array of Constraint instances representing the validation rules.
 * @param value - The input value to validate.
 * @param externalErrors - An optional array of external error objects.
 * @returns An object containing validation-related properties and functions.
 */
export function useValidator<T>(constraints?: Constraint<T>[], value?: T, externalErrors?: ConstraintSupport<T>["externalErrors"]) {
    /**
     * Unique input identifier, needed for validation.
     */
    const uniqueId = useRef<string>(uuidv4());

    const validationStore = useStore();
    const validationDispatch = useDispatch();
    const errors = useSelector((state: ValidationStoreState | undefined) => {
        if (externalErrors)
            return externalErrors;

        return state?.errors && uniqueId.current ? state.errors[uniqueId.current] : undefined;
    });

    const validationTrigger = useSelector((state: ValidationStoreState | undefined) => state ? state.validationTrigger : undefined);

    const showErrorsPopper = useMemo(() => {
        if (errors?.length)
            return (errors as Constraint<T>[]).filter(err => !err.hideMessage).length > 0;

        return false;
    }, [errors]);

    const resetValidation = () => {
        if (validationStore && constraints && uniqueId.current)
            validationDispatch(cleanErrors({ fieldId: uniqueId.current }));
    };

    const revalidate = (value: T) => {
        if (validationStore && constraints && uniqueId.current) {
            if (isFilledValue(value)) {
                validationDispatch(analyzeInput({ fieldId: uniqueId.current, value: value }));
            } else {
                resetValidation();
            }
        }
    };

    /**
     * Registering input's constraints.
     */
    useEffect(() => {
        if (validationStore) {
            validationDispatch(registerInput({ fieldId: uniqueId.current, constraints: constraints }));
        }
    }, [constraints]);

    useEffect(() => {
        return () => {
            if (validationStore) {
                validationDispatch(deregisterInput({ fieldId: uniqueId.current }));
            } else {
                console.error(`Failed to deregister input ${uniqueId.current}. Validator is prone to bugs!`);
            }
        };
    }, []);

    /**
     * Every time use forces validation, reanalyzing input's constraints and updating active errors.
     */
    useEffect(() => {
        if (validationTrigger && constraints) {
            validationDispatch(analyzeInput({ fieldId: uniqueId.current, value: value }));
        }
    }, [validationTrigger]);


    return {
        uniqueId,
        validationStore,
        validationDispatch,
        errors,
        validationTrigger,
        resetValidation,
        revalidate,
        showErrorsPopper
    };
}

/**
 * Simple utility to remove all fields, which contains null, undefined or an empty array
 * @param factory factory as if you used useMemo (which is being used underneath)
 * @param deps deps for useMemo
 */
export function useFilters<T extends Record<string, any>>(factory: () => T, deps: any[]) {
    return useMemo(() => removeEmpty(factory()), deps);
}

/**
 * A custom React hook for filtering a range of numbers with debouncing.
 *
 * @param data - The initial range data as an array of numbers. (optional)
 * @param cooldown - The debounce timeout in milliseconds. Defaults to 2000ms.
 * @returns A tuple containing:
 *
 *      - The current range state
 *      - A function to update the range state
 *      - The debounced range state
 *
 * @example
 * // Using the custom hook in a functional component
 * const MyComponent: React.FC = () => {
 *   const [range, setRange, debouncedRange] = useRangeFilter([0, 100], 1000);
 *   // ... your component logic
 * };
 */
export function useRangeFilter(data?: number[], cooldown = 2000): [number[] | undefined, Dispatch<React.SetStateAction<number[] | undefined>>, number[] | undefined] {
    // ---> Purchasing Price Range configuration <--- //
    const [range, setRange] = useState<number[] | undefined>(data);
    const [debouncedRange, setDebouncedRange] = useState<number[]>();

    // Cooldown to make request only when slider is inactive for some time
    const rangeCooldown = useCallback(_.debounce(setDebouncedRange, cooldown), [setDebouncedRange, cooldown]);
    useEffect(() => {
        rangeCooldown(range);
        return rangeCooldown.cancel;
    }, [range]);

    return [range, setRange, debouncedRange];
}

/**
 * Removes empty properties from an object.
 *
 * @param obj - The object from which to remove empty properties.
 * @returns A new object with empty properties removed.
 *
 * @example
 * const input = { a: 1, b: "", c: null, d: undefined, e: [] };
 * const output = removeEmpty(input); // { a: 1 }
 */
export function removeEmpty<T extends Record<string, any>>(obj: T): T {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => isFilledValue(value))) as T;
}

export function deepRemoveEmpty<T extends Record<string, any>>(obj: T): T {
    return _.pickBy(obj, value => {
        // Check if the value is false, and retain it if so
        if (typeof value === "boolean") {
            return true;
        }

        // If it's an object (and not an array), apply the function recursively
        if (_.isObject(value) && !_.isArray(value)) {
            // Check if the recursive application returns a non-empty object
            return !_.isEmpty(deepRemoveEmpty(value));
        }

        // Use lodash's isEmpty to check for other empty values
        return !_.isEmpty(value);
    }) as T;
}


/**
 * Checks if a given value is considered "filled".
 * A filled value is not null, not undefined, not an empty array, and if stringChecker is true, not an empty or whitespace-only string.
 *
 * @param value - The value to check.
 * @param stringChecker - Whether to consider empty or whitespace-only strings as not filled. Defaults to true.
 * @returns True if the value is considered filled, otherwise false.
 *
 * @example
 * isFilledValue(null); // false
 * isFilledValue("   "); // false
 * isFilledValue(0); // true
 * isFilledValue("hello"); // true
 * isFilledValue([], false); // false
 */
export function isFilledValue(value: any, stringChecker = true) {
    return (value != null && value != undefined) && (Array.isArray(value) ? value.length > 0 : true) && (stringChecker ? typeof value === "string" ? value.trim().length > 0 : true : true);
}

/**
 * Updates elements in an array of objects based on a condition using a transformation function.
 * This function is useful for simplifying the setState method with objects in React.
 *
 * @param state - The initial array of objects.
 * @param isNeeded - A function that takes an object and its index as parameters and returns a boolean indicating whether the object should be updated.
 * @param changeState - A function that takes the current object as a parameter and returns a new object with the desired updates.
 * @returns A new array with the updated objects.
 *
 * @example
 * // Update the age property of users with the name "John"
 * const users = [
 *   { name: "John", age: 25 },
 *   { name: "Jane", age: 30 },
 *   { name: "John", age: 40 },
 * ];
 *
 * const updatedUsers = updateObjectsArray(
 *   users,
 *   (user) => user.name === "John",
 *   (user) => ({ ...user, age: user.age + 1 })
 * );
 * // updatedUsers: [
 * //   { name: "John", age: 26 },
 * //   { name: "Jane", age: 30 },
 * //   { name: "John", age: 41 },
 * // ]
 */
export function updateObjectsArray<T extends Record<string, any>>(state: T[], isNeeded: (item: T, index: number) => boolean, changeState: (prevState: T) => T): T[] {
    return state.map((item, index) => isNeeded(item, index) ? changeState(item) : item);
}

export function genT(value: PredefinedTranslations) {
    const { t } = useTranslation("", { keyPrefix: "general" });

    return t(value);
}

/**
 * Determines the appropriate text form for a given number based on its value.
 * This function is typically used to handle the pluralization of words in languages
 * with complex plural forms, such as Russian.
 *
 * @param n - The number to use for determining the correct text form.
 * @param text_forms - An array of text forms, where:
 *      text_forms[0] - singular form (e.g., 1 item)
 *      text_forms[1] - plural form for a few items (e.g., 2-4 items)
 *      text_forms[2] - plural form for many items (e.g., 5 or more items)
 * @returns The appropriate text form based on the provided number.
 *
 * @example
 * // English-like pluralization
 * const count = 5;
 * const forms = ["item", "items", "items"];
 * const text = `${count} ${declOfNum(count, forms)}`; // "5 items"
 *
 * @example
 * // Russian-like pluralization
 * const count = 22;
 * const forms = ["товар", "товара", "товаров"];
 * const text = `${count} ${declOfNum(count, forms)}`; // "22 товара"
 */
export function declOfNum(n: number, text_forms: string[]) {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 == 1) {
        return text_forms[0];
    }
    return text_forms[2];
}

/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param num - The number to round.
 * @param places - The number of decimal places to round the number to.
 * @returns The rounded number.
 *
 * @example
 * const num = 3.14159265;
 * const rounded = roundTo(num, 2); // rounded will be 3.14
 */
export function roundTo(num: number | undefined | null, places: number) {
    if (!num) return 0;
    return +(Math.round(parseFloat(num + "e+" + places)) + "e-" + places);
}

/**
 * Normalizes a string input into a number of the specified type.
 *
 * @param input - The string input to be normalized.
 * @param type - The type of number to return: 'int' for integers or 'float' for floating-point numbers.
 * @returns A number of the specified type if the input can be parsed, otherwise undefined.
 *
 * @example
 * // Convert a string to an integer
 * const intNumber = normalizeNumber("42", "int"); // intNumber will be 42
 *
 * @example
 * // Convert a string to a floating-point number
 * const floatNumber = normalizeNumber("3.14", "float"); // floatNumber will be 3.14
 *
 * @example
 * // Invalid input returns undefined
 * const invalidNumber = normalizeNumber("abc", "int"); // invalidNumber will be undefined
 */
export function normalizeNumber(input: string, type: "int" | "float"): number | undefined {
    const value = type === "int" ? parseInt(input) : parseFloat(input);

    return isNaN(value) ? undefined : value;
}


/**
 * A utility function to safely obtain a context value. If the context value is undefined,
 * an exception is thrown.
 *
 * @param context - The context to retrieve the value from.
 * @param name - The name of the context, used to make the exception more informative. (optional)
 * @returns The context value as a non-nullable type.
 *
 * @example
 * // Assuming MyContext is a React context
 * function MyComponent() {
 *   // Safely obtain the context value or throw an error if undefined
 *   const contextValue = useContextSafely(MyContext, "MyContext");
 *   // ... your component logic
 * }
 */
export function useContextSafely<T>(context: Context<T>, name?: string): NonNullable<T> {
    const acquiredContext = React.useContext(context);

    if (acquiredContext === undefined)
        throw new Error(`use${name ?? "Unnamed"} must be used within a ${name ?? "Unnamed"} Provider`);

    return acquiredContext as NonNullable<T>;
}

/**
 * Transforms a location object into a dropdown options structure.
 * The transformed data represents "Store - Section" pairs.
 *
 * @param store - A Location.Slim object containing the location information.
 * @returns An array of objects representing dropdown options.
 *
 * @example
 * const store: Location.Slim = {
 *   id: 1,
 *   name: 'Store A',
 *   sections: [
 *     { id: 101, name: 'Section A1' },
 *     { id: 102, name: 'Section A2' },
 *   ],
 * };
 *
 * const options = locationSlimToOption(store);
 * // options: [
 * //   { store: { id: 1, name: 'Store A' }, section: { id: 101, name: 'Section A1' } },
 * //   { store: { id: 1, name: 'Store A' }, section: { id: 102, name: 'Section A2' } },
 * // ]
 */
export function locationSlimToOption(store: Location.Slim): LocationOption[] {
    // To allow displaying values as "Store - Section" pairs, we need to transform the original data, acquired from the endpoint
    if (store.sections && store.sections.length) {
        return store.sections.map(section => ({
            store: {
                id: store.id,
                name: store.name
            },
            section: {
                id: section.id,
                name: section.name
            }
        }));
    } else {
        return [
            {
                store: {
                    id: store.id,
                    name: store.name
                }
            }
        ];
    }
}

/**
 * Transforms a currency object into an array of currency objects with name, symbol, and code properties.
 *
 * @param currencyObj - An object containing currency code keys with name and symbol properties.
 * @returns An array of currency objects with name, symbol, and code properties.
 *
 * @example
 * const currencyObj = {
 *   USD: { name: 'United States Dollar', symbol: '$' },
 *   EUR: { name: 'Euro', symbol: '€' },
 * };
 *
 * const currencyArr = transformCurrencyObjectToArr(currencyObj);
 * // currencyArr: [
 * //   { name: 'United States Dollar', symbol: '$', code: 'USD' },
 * //   { name: 'Euro', symbol: '€', code: 'EUR' },
 * // ]
 */
export function transformCurrencyObjectToArr(currencyObj: Record<string, { name: string, symbol: string }>) {
    const currencyArr = [];
    for (const code in currencyObj) {
        const { name, symbol } = currencyObj[code];
        currencyArr.push({ name, symbol, code });
    }
    return currencyArr;
}

export function validateConstraintsInline<T>(value: T, constraints: Constraint<T>[]): Constraint<T>[] {
    const failedConstraints: Constraint<T>[] = [];

    for (const cnstr of constraints) {
        if (!cnstr.validate(value))
            failedConstraints.push(cnstr);
    }

    return failedConstraints;
}

/**
 * Removes specified fields from an object and returns a new object without those fields.
 *
 * @param obj - The original object to remove fields from.
 * @param fields - An array of keys (field names) to be removed from the object.
 * @returns A new object without the specified fields.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * const fields = ['a', 'c'];
 *
 * const newObj = deleteField(obj, fields);
 * // newObj: { b: 2 }
 */
export function deleteFields<T>(obj: T, fields: (keyof T)[]) {
    const copy = Object.assign({}, obj);

    for (const field of fields) {
        delete copy[field];
    }

    return copy;
}

/**
 * Converts a given object into a FormData instance, allowing for easy submission of complex data structures.
 * The function can also handle nested objects and arrays.
 *
 * @param obj - The original object to be converted to FormData.
 * @param formData - An optional FormData instance to be used for appending values. Default is a new FormData instance.
 * @param namespace - An optional string used as a prefix for field names when handling nested objects. Default is an empty string.
 * @returns A FormData instance containing the data from the given object.
 *
 * @example
 * const obj = {
 *   name: "John Doe",
 *   age: 30,
 *   hobbies: ["reading", "coding"],
 *   address: {
 *     street: "123 Main St",
 *     city: "New York",
 *     zip: "12345",
 *   },
 * };
 *
 * const formData = objectToFormData(obj);
 * // formData will have entries for name, age, hobbies[0], hobbies[1], address[street], address[city], and address[zip]
 */
export function objectToFormData(obj: object, formData: FormData = new FormData(), namespace = ""): FormData {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const propName = namespace ? `${namespace}[${key}]` : key;
            const value: any = (obj as any)[key];

            if (value instanceof File) {
                formData.append(propName, value);
            } else if (Array.isArray(value)) {
                for (let i = 0; i < value.length; i++) {
                    objectToFormData(value[i], formData, `${propName}[${i}]`);
                }
            } else if (typeof value === "object" && value !== null) {
                objectToFormData(value, formData, propName);
            } else {
                formData.append(propName, value);
            }
        }
    }

    return formData;
}

/**
 * Checks if two arrays have the same unique values.
 * The function does not consider the order of elements or duplicates.
 *
 * @param array1 - The first array of strings to compare.
 * @param array2 - The second array of strings to compare.
 * @returns A boolean indicating whether the arrays have the same unique values.
 *
 * @example
 * const array1 = ["apple", "banana", "orange"];
 * const array2 = ["banana", "apple", "orange"];
 * const array3 = ["apple", "banana", "grape"];
 *
 * console.log(arraysHaveSameValues(array1, array2)); // true
 * console.log(arraysHaveSameValues(array1, array3)); // false
 */
export function arraysHaveSameValues(array1: string[], array2: string[]): boolean {
    if (array1.length !== array2.length) {
        return false;
    }
    array1.sort();
    array2.sort();
    for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
}


export function downloadBlobExport(response: any | (() => string), prefix: string, extension = "xlsx") {
    const downloadUrl = window.URL.createObjectURL(new Blob([typeof response === "function" ? response() : response.data]));

    const link = document.createElement("a");

    link.href = downloadUrl;

    link.setAttribute("download", `${dayjs().format("YYYY_MM_DD")}_${prefix}.${extension}`); //any other extension

    document.body.appendChild(link);

    link.click();

    link.remove();
}

/**
 * Determines which optional fields should be required based on whether any other field in the given scheme is filled.
 *
 * @template T - The type that extends Record<string, any>, representing the scheme of fields.
 * @template R - The type that extends the keys of T, representing the optional fields.
 *
 * @param {T} scheme - The scheme of fields with their values.
 * @param {R[]} optional - An array of field names that are considered optional but should be required if any other fields are filled.
 *
 * @returns {string[]} - An array of optional field names that should be required if any non-optional field in the scheme is filled.
 * This will be empty if no non-optional field in `scheme` is filled.
 *
 * @example
 *
 * const scheme = { name: 'John', age: 30, email: null };
 * const optional = ['name'];
 * const result = requiredIfAnyFilled(scheme, optional);
 * // result will be ['email'] because 'name' is optional and other fields ('age') are filled
 */
export function requiredIfAnyFilled<T extends Record<string, any>, R extends keyof T>(
    scheme: T,
    optional: R[] = []
): string[] {
    const cleanedScheme = removeEmpty(_.omit(scheme, optional));
    const isAnyFieldFilled = !_.isEmpty(cleanedScheme);

    const requiredFields: string[] = [];

    if (isAnyFieldFilled) {
        Object.keys(scheme).forEach(key => {
            if (!(key in cleanedScheme) && !optional.includes(key as R)) {
                requiredFields.push(key);
            }
        });
    }

    return requiredFields;
}

/**
 * Determines which optional fields should be required based on whether any other field in the given scheme is filled.
 * Additionally, the function checks if the filled fields have a length less than the specified minimum length.
 * This is useful for validating form inputs that require a minimum length.
 *
 * @template T - The type that extends Record<string, any>, representing the scheme of fields.
 * @template R - The type that extends the keys of T, representing the optional fields.
 *
 * @param {T} scheme - The scheme of fields with their values.
 * @param {R[]} optional - An array of field names that are considered optional but should be required if any other fields are filled.
 * @param {number} minLength - The minimum length required for the filled fields.
 *
 * @returns {string[]} - An array of optional field names that should be required if any non-optional field in the scheme is filled
 *  and has a length less than `minLength`.
 *
 * @example
 * const scheme = { name: 'John', street: "New Jersy 12", city: "LA" email: 'null' };
 * const optional = ['email'];
 * const minLength = 3;
 * const result = requiredIfAnyWithLength(scheme, optional, minLength);
 * /result will be ['city'] because 'email' is optional and other fields ('name', 'street') are filled and have a length less than 3
 */
export function requiredIfAnyWithLength<T extends Record<string, any>, R extends keyof T>(
    scheme: T,
    optional: R[] = [],
    minLength = 0
): string[] {
    const cleanedScheme = removeEmpty(_.omit(scheme, optional));
    const isAnyFieldFilled = !_.isEmpty(cleanedScheme);

    const fieldsNeedingLengthValidation: string[] = [];

    if (isAnyFieldFilled) {
        Object.keys(scheme).forEach(key => {
            // Skip optional fields
            if (optional.includes(key as R)) {
                return;
            }

            const value = scheme[key];

            if (value?.length < minLength) {
                fieldsNeedingLengthValidation.push(key);
            }
        });
    }

    return fieldsNeedingLengthValidation;
}

export function calculateOrderPrice(units: number, unitPrice: number, discountPercentage: number): number {
    const discountAmount = (unitPrice * units * discountPercentage) / 100;
    return unitPrice * units - discountAmount;
}

export function calculateOrderPriceWithTax(units: number, unitPrice: number, discountPercentage: number, taxPercentage: number): number {
    const orderPrice = calculateOrderPrice(units, unitPrice, discountPercentage);
    const taxAmount = (orderPrice * taxPercentage) / 100;
    return orderPrice + taxAmount;
}

export function getCountryFlag(countryCode: string): string {
    return `${COUNTRY_FLAGS_URL}/${countryCode.toLowerCase()}.svg`;
}

export function jsxSwitch<T extends Record<string | number, JSX.Element>>(records: T, value?: keyof T | (() => keyof T)) {
    if (value === null || value === undefined) return "";

    const val: keyof T = typeof value === "function" ? value() : value;

    return records[val];
}

// export function useDatesRangeFilter() {
//     const [datesRange, setDatesRange] = useState<DatepickerRange>({
//         from: null,
//         to: null
//     });

//     const [filterDatesRangeFormatted, setFilterDatesRangeFormatted] = useState<{ from: string; to: string }>();

//     useEffect(() => {
//         if (datesRange.to && datesRange.from) {
//             setFilterDatesRangeFormatted({
//                 from: datesRange.from.format("YYYY-MM-DD"),
//                 to: datesRange.to.format("YYYY-MM-DD")
//             });
//         } else {
//             setFilterDatesRangeFormatted(undefined);
//         }
//     }, [datesRange]);

//     return {
//         datesRange,
//         setDatesRange,
//         filterAdaptedDatesRange: filterDatesRangeFormatted
//     };
// }

export function useDatesRangeFilter() {
    const [datesRange, setDatesRange] = useState<DatepickerRange>({
        from: null,
        to: null
    });

    const [filterDatesRangeFormatted, setFilterDatesRangeFormatted] = useState<{ from: string; to: string }>();

    useEffect(() => {
        if (datesRange.from && datesRange.to) {
            setFilterDatesRangeFormatted({
                from: datesRange.from.format("YYYY-MM-DD"),
                to: datesRange.to.format("YYYY-MM-DD")
            });
        } else if (datesRange.from) {
            // If only 'from' is set, set both 'from' and 'to' to the same date
            setFilterDatesRangeFormatted({
                from: datesRange.from.format("YYYY-MM-DD"),
                to: datesRange.from.format("YYYY-MM-DD")
            });
        } else {
            setFilterDatesRangeFormatted(undefined);
        }
    }, [datesRange]);

    return {
        datesRange,
        setDatesRange,
        filterAdaptedDatesRange: filterDatesRangeFormatted
    };
}


export function stopPropagate(callback: (og_e: React.SyntheticEvent) => void) {
    return (e: React.SyntheticEvent) => {
        e.stopPropagation();
        callback(e);
    };
}

export function smartParseFloat(value: string | number | undefined | null): number | undefined {
    if (value === undefined || value === null || value === "")
        return undefined;

    return parseFloat(value.toString().replaceAll(",", "."));
}

export function preciseDecimal(value: number, precision: number) {
    return parseFloat(value.toPrecision(precision));
}

export function toFixedDecimal(value: number, precision: number) {
    return parseFloat(value.toFixed(precision));
}

export function normalizePrice<T extends string | number | null | undefined>(value: T): T extends string ? number : T {
    const price = smartParseFloat(value);
    return (price == undefined ? undefined : +price?.toFixed(ORDER_PRICE_PRECISION)) as (T extends string ? number : T);
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function isAddressFilled<T extends Record<string, any>, R extends keyof T>(
    address?: T | null,
    omit: R[] = []
): address is T {
    if (!address) return false;

    const cleanedScheme = removeEmpty(_.omit(address, omit));
    return !_.isEmpty(cleanedScheme);
}

export function useEchoEffect<SocketData>(
    channel: string | (() => string),
    event: string,
    onEvent: (e: SocketData) => void,
    onUnsubscribe?: () => void,
    dependencies?: any[],
    shouldLeave?: boolean
) {
    useEffect(() => {
        const channelUnwrapped = typeof channel === "function" ? channel() : channel;

        const stopListening = (c: Channel | null) => {
            c?.stopListening(event, onUnsubscribe);
            if (shouldLeave) {
                laravelEcho.leave(channelUnwrapped);
            }
        };

        let cnl: Channel | null = null;
        try {
            cnl = laravelEcho.private(channelUnwrapped);

            cnl.listen(event, onEvent);
        } catch (e) {
            console.error(`useEchoEffect:[${channel} >> ${event}]`, e);
            stopListening(cnl);
        }

        return () => {
            stopListening(cnl);
        };
    }, dependencies ?? []);
}

export const isIntegrationSlug = (value: string): value is Integration.Components.Slug => {
    return value === "shipmondo" ||
        value === "tripletex" ||
        value === "lime" ||
        value === "poweroffice";
};

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

export function centerSVGElement(parent: SVGSVGElement, elToCenter?: SVGGraphicsElement | null, offset: { x: number, y: number } = { x: 0, y: 0 }) {
    if(!elToCenter) return;

    const bbox = elToCenter.getBBox() || { x: 0, y: 0, width: 0, height: 0 };

    const svgWidth = parent.clientWidth || parent.getBoundingClientRect().width;

    // Calculating the center position
    const centerX = (svgWidth - bbox.width) / 2 - bbox.x;

    elToCenter.setAttribute("transform", `translate(${centerX + offset.x}, ${offset.y})`);
}

export function toastError(e: Error) {
    if (isErrorWithMessage(e)) {
        toast.error(e.message);
    } else {
        toast.error(i18n.t("general.responses.somethingWentWrong"));
    }
}

export type Nullish<T> = T extends object
    ? { [K in keyof T]?: Nullish<T[K]> | null }
    : T;


export enum PredefinedTranslations {DropdownsALL = "dropdownAll"}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type RequiredBy<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>
export type ArrayElementType<T> = T extends Array<infer U> ? U : never;