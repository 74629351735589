import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "@assets/stylesheets/_login.scss";
import "./index.scss";

import BrandLogo from "@assets/images/logo.svg";
import ImLoginDashboard from "@assets/images/im_login_dashboard.svg";
import BaseButton from "@reusables/BaseButton";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { authApi } from "@redux/features/auth/authApi";
import BaseInputsGrid from "@reusables/BaseInputsGrid";
import BaseInput from "@reusables/BaseInput";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "@redux/hooks";
import { authenticate, setUser } from "@redux/features/auth/authSlice";
import { companiesApi } from "@redux/features/companies/companiesApi";
import { integrationsApi } from "@redux/features/integrations/integrationsApi";
import { CompanyPayload } from "@redux/features/auth/types";

const formScheme = z.object({
    email: z.string().email(),
    password: z.string().min(8).max(100),
    remember_me: z.boolean()
});

type FormTyping = z.infer<typeof formScheme>;

export default function LoginPage() {
    const { t } = useTranslation();

    const history = useHistory();
    const dispatch = useAppDispatch();

    const [isLoginLoading, setIsLoginLoading] = useState(false);

    const { control, handleSubmit } = useForm<FormTyping>({
        resolver: zodResolver(formScheme),
        defaultValues: {
            email: "",
            password: "",
            remember_me: false
        }
    });

    const onSubmit = handleSubmit(async (credentials) => {
        setIsLoginLoading(true);

        let meQuery, companyQuery, integrationsQuery;

        try {
            const loginResponse = await dispatch(authApi.endpoints.login.initiate(credentials)).unwrap(); // tokens

            // Setting authentication tokens
            dispatch(authenticate({
                access: loginResponse.token,
                refresh: loginResponse.refresh_token
            }));

            // Getting extended user information
            meQuery = dispatch(authApi.endpoints.getMe.initiate(undefined, { forceRefetch: true }));
            const meData = await meQuery.unwrap();

            companyQuery = dispatch(companiesApi.endpoints.getCompany.initiate(meData.company.id, { forceRefetch: true }));
            const companyData = await companyQuery.unwrap();

            integrationsQuery = dispatch(integrationsApi.endpoints.getIntegrations.initiate(undefined, { forceRefetch: true }));
            const integrationsResponse = await integrationsQuery.unwrap();

            dispatch(setUser({
                ...meData,
                company: {
                    id: companyData.id,
                    name: companyData.name,
                    currency: companyData.currency,
                    integrations: integrationsResponse.integrations?.reduce((acc, integration) => {
                        acc[integration.slug] = {
                            id: integration.id,
                            integrated: integration.integrated
                        };

                        return acc;
                    }, {} as NonNullable<CompanyPayload["integrations"]>)
                }
            }));

            history.push("/dashboard/main");
        } catch (err: any) {
            toast.error(err.message);
        } finally {
            meQuery?.unsubscribe();
            companyQuery?.unsubscribe();

            setIsLoginLoading(false);
        }
    });

    return (
        <main className="login">
            <div className="section login-section">
                <div className="container-lg mx-auto">
                    <div className="login-top">
                        <a href="/">
                            <img src={BrandLogo} alt="Suppli Brand Logo" />
                        </a>
                    </div>
                    <div className="login-main">
                        <div className="login-left">
                            <h1>{t("loginPage.loginMain")}</h1>
                            <form onSubmit={onSubmit} className="login-form">
                                <BaseInputsGrid cols={1}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}
                                                label={t("loginPage.emailLabel")}

                                                sx={{
                                                    "& input": {
                                                        border: 0,
                                                        background: "#F9F9F9"
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <BaseInput
                                                {...field}
                                                error={fieldState.error}

                                                label={t("loginPage.passwordLabel")}
                                                type="password"

                                                sx={{
                                                    "& input": {
                                                        border: 0,
                                                        background: "#F9F9F9"
                                                    }
                                                }}
                                            />
                                        )}
                                    />

                                    <div className="login-controls">
                                        <Controller
                                            name={"remember_me"}
                                            control={control}
                                            render={({ field }) => (
                                                <div className="checkbox-wrapper">
                                                    <input type="checkbox" id="remember" checked={field.value} />
                                                    <label htmlFor="remember">
                                                        {t("loginPage.rememberLabel")}
                                                    </label>
                                                </div>
                                            )}
                                        />
                                        <a href="/forgot-password" className="login-forgot">
                                            {t("loginPage.forgotPasswordLabel")}
                                        </a>
                                    </div>
                                </BaseInputsGrid>

                                <BaseButton
                                    text={t("loginPage.logInBtn")}
                                    textColor="white"
                                    size="md"
                                    buttonWidth="100%"
                                    loading={isLoginLoading}
                                />

                                <div className="login-footnote">
                                    <span>{t("loginPage.noAccountLabel")}</span>
                                    <a href="/registration">{t("loginPage.registerBtn")}</a>
                                </div>
                            </form>
                        </div>
                        <div className="login-right">
                            <div className="login-pic">
                                <img src={ImLoginDashboard} alt="Suppli Login Dashboard" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
