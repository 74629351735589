const translationEN = {
  navbar: {
    home: "Home",
    product: "Product",
    pricing: "Pricing",
    partners: "Partners",
    about_us: "About",
    support: "Support",
    news: "News",
    login: "Log In"
  },
  footer: {
    desc: `
            Get Suppli cloud-based WMS to streamline your warehouse operations. Stay up-to-date and fully in control of your inventory to keep your customers happy and move your business forward.
        `,
    copyright: "Copyright 2023, By Suppli",
    company: {
      title: "Company"
    },
    product: {
      title: "Product",
      links: {
        1: "Start free trial",
        2: "FAQ"
      }
    },
    followUs: "Follow us"
  },
  homePage: {
    welcomeBlock: {
      main: "Get full control of inventory at your warehouse",
      desc: "With Suppli you will easily be able to have full control of your inventory, purchases, orders, shipments, and goods receipts.",
      btnText: "Get started"
    },
    whyUsBlock: {
      preTitle: "Why Choose Us?",
      title: "Benefits of a warehouse management system",
      cards: {
        organize: {
          main: "Organize",
          desc: "Easily organize and keep track of inventory"
        },
        reports: {
          main: "Reports",
          desc: "Never run out of stock with warnings and reports"
        },
        manageSales: {
          main: "Manage Sales",
          desc: "Manage your sales with a smarter warehouse system"
        },
        control: {
          main: "Control",
          desc: "Total control and visibility in the warehouse in real-time"
        },
        integrations: {
          main: "Integrations",
          desc: "Integrations to ERP, webshop, financial and freight systems"
        }
      }
    },
    productBlock: {
      preTitle: "Suppli",
      title: "Product",
      descriptions: {
        1: `
                    is a cloud-based warehouse system
                    that helps you to get full control over products,
                    purchasing, sales orders, delivery, stock flow, inventory
                    counting.
                `,
        2: `
                    helps you to optimize your
                    warehouse, so you'll get a full overview of crucial
                    factors such as stock, delivery schedules, and order sizes.
                `,
        3: `
                    logs all activity on your products,
                    so it is easy to see the full history of each item. For
                    example, when was it last sold, if it has been moved to
                    another location, breakage, if there are any orders and if
                    it has been made any changes in the system and who has made
                    them.
                `,
        4: `
                    can be integrated with different
                    systems and communicates in real-time. This communication
                    does so that the system is updated with stock value, invoice
                    basis, online stock status, and automatic web order
                    retrieval.
                `
      },
      productFooter: {
        btnMain: "View More",
        videoBtn: "Watch Video"
      }
    },
    pricingBlock: {
      preTitle: "Get started easily and quickly",
      title: "Pricing",
      desc: `
                To use Suppli as a customer you need to mandate and have the start
                package, one premium license, and one of the support packages in order to be
                up and running.
            `,
      pricingItems: {
        1: {
          title: "Start package",
          features: {
            1: "Suppli software",
            2: "3 hours online employee training in use of Suppli*",
            3: "Setup Suppli account",
            4: "Import products, customers and suppliers",
            5: "Setup integrations"
          },
          price: "Kr 15 000, -",
          btn: "Buy Now",
          footnotes: {
            1: `
                        *If you want us to come to your company and have training in
                        use of Suppli, contact us for prices.
                        `,
            2: `
                            ** Up to three import files. The files must be in correct format. Please see our example templates.
                        `,
            3: `
                            ***Up to two integrations. The integration must be in our portfolio.
                        `
          }
        },
        2: {
          title: "License",
          premium: {
            title: "Premium",
            features: {
              1: "Full access to program"
            },
            price: "Kr 400/Month pr user"
          },
          warehouse: {
            title: "Warehouse",
            features: {
              1: `
                                Access to delivery, stock flow, shipment, inventory
                                counting and products scanning
                            `
            },
            price: "Kr 200/Month pr user"
          },
          accounting: {
            title: "Accounting",
            features: {
              1: `
                                Access to stock value, sales statistics and inventory
                                counting
                            `
            },
            price: "Kr 200/Month pr user"
          },
          businessP1: {
            title: "Business package 1",
            features: {
              1: "5 premium licenses, 5 warehouse licenses, 1 accounting"
            },
            price: "Kr 2000/Month"
          },
          businessP2: {
            title: "Business package 2",
            features: {
              1: "10 premium licenses, 10 warehouse licenses, 3 accounting"
            },
            price: "Kr 4500/Month"
          },
          btn: "Buy Now"
        },
        3: {
          title: "Support",
          gold: {
            title: "Gold (all inclusive)",
            features: {
              1: "10 hours telephone support monthly.",
              2: "Help with import of products, customers, suppliers.",
              3: "Help with price adjustments.",
              4: "Extra training sessions in use of Suppli.",
              5: "Free email and chat support."
            },
            price: "Kr 2999/Month"
          },
          silver: {
            title: "Silver",
            features: {
              1: "3 hours telephone support monthly.",
              2: "Free email and chat support"
            },
            price: "Kr 1499/Month"
          },
          bronze: {
            title: "Bronze",
            features: {
              1: "1 hour telephone support monthly",
              2: "Free email and chat support"
            },
            price: "Kr 999/Month"
          },
          btn: "Buy Now",
          footnotes: {
            1: `
                            *Import files must be in correct format. See our example files. Working hours related to import is deducted from the included support hours in your package.
                        `
          }
        }
      },
      note: "*All prices are excluded VAT",
      footerTexts: {
        1: `
                    If you as a customer want us to come to you and do consultant
                    work or if you need help planning and customizing a warehouse plan
                    for your business, please contact us.
                `,
        2: `
                    We are a company located in Larvik and we will invoice travel
                    costs such as hotel accommodation, fuel expenses, flight tickets,
                    and consulting hours. Note that we invoice half price for
                    consulting hours for such agreements. Contact us for prices.
                `,
        3: `
                    We also have all the equipment you need for warehouse management
                    such as scanners, laptops, mobile devices, iPads, printers,
                    barcode scanners, RFID scanners.
                `
      }
    },
    pricingBlockUSD: {
      preTitle: "Get started easily and quickly",
      title: "Pricing",

      desc: `
                To use Suppli as a customer you need to mandate and have the start
                package, one premium license, and one of the support packages in order to be
                up and running.
            `,
      pricingItems: {
        1: {
          title: "Start package",
          features: {
            1: "Suppli software",
            2: "3 hours online employee training in use of Suppli*",
            3: "Setup Suppli account",
            4: "Import products, customers and suppliers",
            5: "Setup integrations"
          },
          price: "USD 39, -",
          btn: "Buy Now",
          footnotes: {
            1: `
                        *If you want us to come to your company and have training in
                        use of Suppli, contact us for prices.
                        `,
            2: `
                            ** Up to three import files. The files must be in correct format. Please see our example templates.
                        `,
            3: `
                            ***Up to two integrations. The integration must be in our portfolio.
                        `
          }
        },
        2: {
          title: "License",
          premium: {
            title: "Premium",
            features: {
              1: "Full access to program"
            },
            price: "USD 39/Month pr user"
          },
          warehouse: {
            title: "Warehouse",
            features: {
              1: `
                                Access to delivery, stock flow, shipment, inventory
                                counting and products scanning
                            `
            },
            price: "USD 19/Month pr user"
          },
          accounting: {
            title: "Accounting",
            features: {
              1: `
                                Access to stock value, sales statistics and inventory
                                counting
                            `
            },
            price: "USD 19/Month pr user"
          },
          businessP1: {
            title: "Business package 1",
            features: {
              1: "5 premium licenses, 5 warehouse licenses, 1 accounting"
            },
            price: "USD 190/Month"
          },
          businessP2: {
            title: "Business package 2",
            features: {
              1: "10 premium licenses, 10 warehouse licenses, 3 accounting"
            },
            price: "USD 425/Month"
          },
          btn: "Buy Now"
        },
        3: {
          title: "Support",
          gold: {
            title: "Gold (all inclusive)",
            features: {
              1: "10 hours telephone support monthly.",
              2: "Help with import of products, customers, suppliers.",
              3: "Help with price adjustments.",
              4: "Extra training sessions in use of Suppli.",
              5: "Free email and chat support."
            },
            price: "USD 285/Month"
          },
          silver: {
            title: "Silver",
            features: {
              1: "3 hours telephone support monthly.",
              2: "Free email and chat support"
            },
            price: "USD 142/Month"
          },
          bronze: {
            title: "Bronze",
            features: {
              1: "1 hour telephone support monthly",
              2: "Free email and chat support"
            },
            price: "USD 96/Month"
          },
          btn: "Buy Now",
          footnotes: {
            1: `
                            *Import files must be in correct format. See our example files. Working hours related to import is deducted from the included support hours in your package.
                        `
          }
        }
      },
      note: "*All prices are excluded VAT",
      footerTexts: {
        1: `
                    If you as a customer want us to come to you and do consultant
                    work or if you need help planning and customizing a warehouse plan
                    for your business, please contact us.
                `,
        2: `
                    We are a company located in Larvik and we will invoice travel
                    costs such as hotel accommodation, fuel expenses, flight tickets,
                    and consulting hours. Note that we invoice half price for
                    consulting hours for such agreements. Contact us for prices.
                `,
        3: `
                    We also have all the equipment you need for warehouse management
                    such as scanners, laptops, mobile devices, iPads, printers,
                    barcode scanners, RFID scanners.
                `
      }
    },
    aboutBlock: {
      preTitle: "Our Team",
      title: "About us",
      descriptions: {
        1: `
                    Suppli AS is a new Norwegian company which is developed in
                    2021. Our main purpose is to give our customers the best
                    solutions that help them to solve problems with warehouse
                    management and development.
                `,
        2: `
                    We are located at Mellombakken 6 in Larvik, just a few minutes
                    away from the E18 and the train station. The best we know is
                    to get visits from our customers where we are more than
                    happy to take you into our demo room. Here you can test out
                    hardware and see our solutions live. Our philosophy is that
                    the interaction between humans and technology should
                    harmonize. That is why we have chosen the partners we have
                    today. They are forward-looking, provide outstanding service
                    and have products that help our customers with efficiency
                    and profit.
                `,
        3: `
                    We look forward to working with you, so contact us for a
                    non-committal chat.
                `
      },
      team: {
        1: {
          position: "Head of development"
        },
        2: {
          position: "KAM Nordics"
        },
        3: {
          position: "CEO"
        },
        4: {
          position: "CMO"
        },
        5: {
          position: "CFO"
        }
      }
    },
    supportBlock: {
      title: "Support",
      text1: `
                Our support team is always ready to help you with your
                problems or questions.
            `,
      text2: "For support contact us on phone number",
      text3: "or support mail",
      text4: `
                You can always use support chat when you are logged in the
                system.
            `,
      postalAddress: `
                Postal address
            `,
      info1: "Suppli AS",
      info2: "Mellombakken 6",
      info3: "3271 Larvik",
      info4: "Norge",

      text5: "SUPPORT OPENING HOURS",
      text6: "Monday - Friday",
      text7: "Saturday - Sunday",
      text8: `
                Closed (outside
                opening hours, you will be invoiced for Kr 1999/hour
                regardless of agreement)
            `
    }
  },
  productPage: {
    productsBlock: {
      header: "Suppli Products",
      desc: "Work smarter and meet all of your business needs with our user-friendly and intuitive WMS"
    },
    productDesc: {
      1: {
        header: "Full control",
        description: "Suppli is a cloud-based warehouse system that provides you to have full control over products, purchasing, sales orders, delivery, stock flow, and inventory counting. Since the system is cloud-based, you can work from everywhere and on any device."
      },
      2: {
        header: "Order management",
        description: "Keep track of all your orders. Suppli optimizes your warehouse so you will get a full overview of crucial factors such as stock, delivery schedules, and order sizes."
      },
      3: {
        header: "Inventory",
        description: "Logs all activity on your products, so it is easy to see the full history of each item. Sales statistics, location movements, breakage, new orders, and a whole lot of other features."
      },
      4: {
        header: "Integrations",
        description: "Suppli loves everyone, so of course, we have an open API. We have already integrations with several different systems such as webshops and accounting software so you don´t have to manually update stock value, invoice basis, online stock status, and automatic web order retrieval."
      }
    }
  },
  pricingPage: {
    getStartedBlock: {
      title: {
        1: "Get Started Now,",
        2: "Pick a Plan"
      },
      description: "To become a customer, you must have a mandatory start package, one premium license, and one of the support packages to be up and running."
    },
    pricingItems: {
      1: {
        title: "Start package",
        features: {
          1: "Suppli software",
          2: "3 hours online employee training in use of Suppli*",
          3: "Setup Suppli account",
          4: "Import products, customers and suppliers",
          5: "Setup integrations"
        },
        price: "Kr 15 000, -",
        btn: "Buy Now",
        footnote: {
          1: `
                        *If you want us to come to your company and have training in
                        use of Suppli, contact us for prices.
                    `,
          2: `
                        [X] ** Up to three import files. The files must be in correct format. Please see our example templates.
                    `,
          3: `
                        [X] ***Up to two integrations. The integration must be in our portfolio.
                    `
        }
      },
      2: {
        title: "License",
        premium: {
          title: "Premium",
          features: {
            1: "Full access to program"
          },
          price: "Kr 400",
          interval: "Month pr user"
        },
        warehouse: {
          title: "Warehouse",
          features: {
            1: `
                            Access to delivery, stock flow, shipment, inventory
                            counting and products scanning
                        `
          },
          price: "Kr 200",
          interval: "Month pr user"
        },
        accounting: {
          title: "Accounting",
          features: {
            1: `
                            Access to stock value, sales statistics and inventory
                            counting
                        `
          },
          price: "Kr 200",
          interval: "Month pr user"
        },
        businessP1: {
          title: "Business package 1",
          features: {
            1: "5 premium licenses, 5 warehouse licenses, 1 accounting"
          },
          price: "Kr 2000",
          interval: "Month"
        },
        businessP2: {
          title: "Business package 2",
          features: {
            1: "10 premium licenses, 10 warehouse licenses, 3 accounting"
          },
          price: "Kr 4500",
          interval: "Month"
        },
        btn: "Buy Now"
      },
      3: {
        title: "Support",
        gold: {
          title: "Gold (all inclusive)",
          features: {
            1: "10 hours telephone support monthly.",
            2: "Help with import of products, customers, suppliers.",
            3: "Help with price adjustments.",
            4: "Extra training sessions in use of Suppli.",
            5: "Free email and chat support."
          },
          price: "Kr 2999",
          interval: "Month"
        },
        silver: {
          title: "Silver",
          features: {
            1: "3 hours telephone support monthly.",
            2: "Free email and chat support"
          },
          price: "Kr 1499",
          interval: "Month"
        },
        bronze: {
          title: "Bronze",
          features: {
            1: "1 hour telephone support monthly",
            2: "Free email and chat support"
          },
          price: "Kr 999",
          interval: "Month"
        },
        btn: "Buy Now",
        footnote: `
                    *Import files must be in correct format. See our example files. Working hours related to import is deducted from the included support hours in your package.
                `
      }
    },
    attendanceBlock: {
      preTitle: "Need help?",
      title: "Attendance",
      description: "We'd love to chat with you about any questions or issues to help you work smarter and develop faster.",
      card: {
        1: {
          title: "Contact Us",
          desc: "Existing customers should contact us if they need a consultation with planning and customizing warehouse for their business"
        },
        2: {
          title: "Consulting Hours",
          desc: "We are a company located in Larvik and we will invoice travel costs such as hotel accommodation, fuel expenses, flight tickets, and consulting hours. Note that we invoice half price for consulting hours for such agreements. Contact us for prices"
        }
      }
    },
    equipmentBlock: {
      preTitle: "Keep up with innovation",
      title: "Equipment",
      description: "We can supply all the equipment you need for warehouse management such as scanners, laptops, mobile devices, Ipads, printers, barcode scanners, RFID scanners",
      items: [
        {
          header: "EA520",
          description: "Rugged smartphone",
          longDesc: `Introducing the EA520, the 5” cutting-edge rugged smartphone delivers powerful barcode reading performance, convenient connectivity, durable but the lightweight design that just fits in the palm of your hand. This enhances the service level for various vertical applications within the retail, hospitality, and field service sectors.`,
          features: [
            "5-inch display with 450nits sunlight readable",
            "Android 11 OS with GMS Certification",
            "Support most 1D / 2D barcodes on mobile screens, even DPM barcodes",
            "Lightweight design that just fits in the palm of your hand.",
            "All-In-One functionality: HF/NFC and 13MP camera",
            "Rugged: Corning® Gorilla® Glass 3 touch display, IP67 rated, and 1.5M drop (1.8M with boot case)",
            "Long-lasting removable 4100mAh battery",
            "Support Bluetooth® 5 / dual-band WLAN, fast roaming / 4G LTE",
            "Support MDM Software Solutions and utilities such as MoboLink."
          ],
          price: "Kr 6990"
        },
        {
          header: "EA630",
          description: "Rugged smartphone",
          longDesc: `The unitech EA630 is a 6-inch rugged mobile smartphone offering 80% screen to body ratio, featuring versatile functionality with powerful data collection. Specially designed for portability, the EA630 is combined with a compact and durable design that makes it an ideal tool to increase higher efficiency for applications in the retail, hospitality and light-duty Field Service.`,
          features: [
            "6-inch rugged smartphone with a screen-to-body ratio of over 80%",
            "Android 10 OS with GMS & AER Certification",
            "All-In-One functionality: 2D Imager, HF/NFC and 16MP camera",
            "Rugged: Corning® Gorilla® Glass 6 touch display, IP65 rated, and 1.2M drop (1.5M with boot case)",
            "Long-lasting removable 4000mAh battery (Up to 16 hours working time)",
            "Support Bluetooth® 5 / dual-band WLAN, fast roaming / 4G LTE",
            "Support MDM Software Solutions and utilities such as AirWatch, SOTI, Ivanti Wavelink, and MoboLink"
          ],
          price: "Kr 8990"
        },
        {
          header: "HT730",
          description: "4-inch rugged handheld terminal",
          longDesc: `The unitech HT730 is an ergonomic one-hand operation handheld terminal with keypad and powerful data collection solutions. Offering Android 10 OS with GMS certification, the HT730 is equipped with Qualcomm® Artificial Intelligence (AI) CPU to deliver users excellent performance for smarter, more intuitive interactions which is suitable to improve your work efficiency in a wide range of applications in warehousing, logistics, and field service.`,
          features: [
            "Android 10 OS with GMS certified",
            "Ergonomic one-handed operation",
            "Superior 2D scanning performance: Support 30-degree scanning tilt down",
            "Superior performance with the scanning range up to 20m away",
            "Support IEEE 802.11a/b/g/n, 802.11ax-ready, 802.11ac Wave 2 with 2X2 MU-MIMO WLAN Standard",
            "6700mAh battery for 20-26 hours operation",
            "True Hot-swap battery design without downtime",
            "1.8M drop-resistant housing (2.4M with bumper)",
            "IP65/IP67 protection against water and dust",
            "Durable keypad with backlight: 38/29 keys with 2.5 million lifetime clicks",
            "Support Bluetooth® 5.1",
            "Support MDM software and utilities such as MoboLink, StageGo, Ivanti Wavelink and StayLinked"
          ],
          price: "Kr 17990"
        },

        // has links
        {
          header: "TSP800II",
          description: "Multifunctional barcode, label, receipt and ticket printer",
          longDesc: `The TSP800II is a unique and versatile thermal printer capable of printing on wider than average media up to 112mm wide.  Combined with a unique A4 raster driver capable of scaling an A4 image onto 112mm wide paper, the TSP800II can be integrated as a cost effective receipt, ticket or label printer or an easy-to-use and compact alternative to standard A4 invoice or report printers in retail and hospitality environments with significant paper and consumables cost savings. The TSP800II incorporates a black mark sensor and is capable of printing on to media up to 150gsm making it an ideal low cost label printer for shelf edge labels or a reliable, high quality ticket printer for wider format tickets.`,
          features: [],
          price: "Kr 7990"
        },
        {
          header: "MS340",
          description: "Handheld imager scanner (1D)",
          longDesc: `The MS340 is a long-distance scanner, meaning you don’t have to make contact with a barcode to read it. Bring greater flexibility and lasting durability to your data collection process.`,
          features: [
            "Long-range scanning: up to 15 inches",
            "Superior scanning: up to 500 scans/sec",
            "Reads all common 1D barcodes including GS1 DataBar and stacked codes",
            "Hands-free stand available",
            "Withstands 5-foot drops to concrete",
            "Interface options: USB, RS232, Keyboard Wedge",
            "Environmentally sealed against moisture and dust",
            "Warranty: 5 Years"
          ],
          price: "Kr 1290"
        },

        {
          header: "HT730UHF",
          description: "RFID rugged handheld terminal",
          longDesc: `The HT730 UHF is an omnidirectional 15M-read-range RFID terminal that reads 750 tags+ per second to let associates quickly access data when managing stock. With the sophisticated IMPINJ R2000 embedded chip, gaining clear visibility of assets and location is never a troublesome task. There’s no need to turn off the device, so your associates can still hot-swap 6700mAh battery on continuous work. An ultra-powerful RFID reader with resistance to dust and water and withstanding 1.8m drop is your best fit for warehousing, field service, and transportation & logistics.`,
          features: [
            "Industry-leading 15M RFID read range",
            "High sensitivity and optimum chip: IMPINJ R2000",
            "Swappable smart battery with lasting 6700mAh capacity",
            "Perform 750+ tag reads per second",
            "Maximum EIRP of 36 dBm",
            "Support Hot-Swap function, keeps applications active up to 1 minute",
            "Integrated various WIFI connections: IEEE 802.11a/b/g/n, 802.11ax-ready, 802.11ac Wave 2 with 2X2 MU-MIMO WLAN Standard",
            "Wirelessly connect with Bluetooth® 5.1",
            "Ultra-sensitive 4” display touch screen",
            "Balanced, ergonomic, and robust gun grip design",
            "Enterprise-durable protection with IP65 protect and 1.8m drop to concrete"
          ],
          price: "Kr 21990"
        }
      ]
    }
  },
  supportPage: {
    faqBlock: {
      preTitle: "The FAQs",
      title: "Support centre",
      desc: `
                We are dedicated to offering outstanding services that guarantee your satisfaction and business success. We are always available and happy to help to provide your company with the competitive advantage it requires to flourish.
            `,
      article1: {
        boldTitle: "How to get help",
        desc1: `
                    Suppli provides the personalized assistance you need to get the competitive edge in your industry. Whatever your goals, issues or needs are, we are always available for a thorough consultation so that you can get the most out of your investment. 
                `,
        desc2: `
                    You are also welcome to utilize the free resources provided on this page to get your questions answered as quickly as possible. If you don’t find the answer you need or wish to get a more detailed explanation, feel free to contact.
                `,
        desc3: `
        If you are already a customer of Suppli, you can always use the support chat once you are logged in. At Suppli, customer success is the top priority!
        `
      }
    },
    subscribeText: "Subscribe to our newsletter and never miss the latest updates from Suppli",
    contactBlock: {
      title: "Contact us",
      subTitle: "Send us a message or call during working hours +47 332 00 955"
    },
    questionsBlock: {
      1: {
        q: `
                    What is Suppli WMS (Warehouse Management System)? 
                `,
        a: `
                    Suppli is a cloud-based warehouse management system that gives you complete control over your goods, purchases, sales orders, deliveries, stock movement, and inventory counts. With Suppli, your warehouse management will become simpler, less costly, and more efficient.
                `
      },
      2: {
        q: `
                How do I know if I need a WMS?
                `,
        a: `
                If you want to stay in full control of your business and inventory and eliminate costly warehouse mistakes, you need a WMS. Having a robust WMS will help you optimize warehouse space and automate operations, get better visibility of your inventory, manage labor more effectively, provide better customer service and scale faster.
                `
      },
      3: {
        q: `
                Why choose Suppli?
                `,
        a: `
                Suppli provides an innovative and user-friendly solution powered by the top technological developments. This will give you a chance to monitor, track and optimize your operations with full control and ease of mind. Available and easily accessible on any device, at any time and in any place.
                `
      },
      4: {
        q: `
                What types of companies are suited for Suppli WMS?
                `,
        a: `
                All types of companies, from small startups to large corporations will benefit from the features of Suppli WMS. In terms of industries, Suppli focuses on serving the following: retail, manufacturing, wholesale, electronics, pharmaceutical, transportation, third-party logistics (3PL), agriculture, food & beverage, health & beauty, and many more. 
                `
      },
      5: {
        q: `
                What functionalities does Suppli WMS provide?
                `,
        a: `
                The goal of Suppli is to ensure that you can easily organize and keep track of your inventory at your business, be it purchasing, sales orders, or stock flow. You will get the full overview of crucial factors such as stock, delivery schedules, and order sizes, as well as get reports and warnings regarding the inventory. Suppli WMS is also easily integrated with ERP, webshop, financial, freight systems, etc.
                `
      },
      6: {
        q: `
                How and in what languages can I get support?
                `,
        a: `
                We are here for you 24/7 by phone, e-mail, and chat. Primary phone languages are English and Norwegian, but we can also provide support in the following languages: German, Russian, Lithuanian, and Swedish.
                `
      },
      7: {
        q: `
                Can I get a demo to try Suppli WMS?
                `,
        a: `
                Absolutely! We will be more than happy to let you test out the hardware and see our solutions live. To learn more about the demo, contact us by phone, email or chat, or get to meet us personally at Mellombakken 6 in Larvik.  
                `
      }
    },
    subscribeBlock: {
      preTitle: "Subscribe",
      title: "Join 2000+ subscribers",
      subscribeTextLeft: "Stay in the loop with everything you need to know.",
      subscribeTextRight1: "Stay in the loop with everything you need to know.",
      subscribeTextRight2: "We care about your data in our privacy policy!",
      subscribeBtn: "Subscribe",
      notifyBtn: "Notify me",
      sendMessage: "Send Message"
    }
  },
  loginPage: {
    loginMain: "Welcome back",
    googleBtn: "Continue with Google",
    orLabel: "OR",
    emailLabel: "Email",
    passwordLabel: "Password",
    rememberLabel: "Remember for 30 days",
    forgotPasswordLabel: "Forgot Password?",
    logInBtn: "Log in",
    noAccountLabel: "Don't have an account?",
    registerBtn: "Sign Up",
    whatNewLabel: "What's New"
  },
  verifyEmailPage: {
    heading: "Check your email to complete registration",
    resend: "Resend email",
    responses: {
      success: "Email sent successfully",
      error: "An error occurred. Please try again later."
    },
    timer: {
      wait: "Please wait",
      beforeResend: "minutes before resending"
    }
  },
  signupPage: {
    responses: {
      error: "An error occurred. Please try again later.",
    },
    yourProfile: {
      heading: "1. Your profile",
      subheading: "Enter the login information for your account",
      fields: {
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        phone: "Phone",
        password: "Password",
        confirmPassword: "Confirm password"
      },
      errors: {
        passwordLength: "Password must be at least 8 characters",
        passwordMismatch: "Passwords must match"
      },
      passwordRules: ["Password must contain at least 8 characters", "Password must contain at least one letter", "Password must contain at least one number"],
      button: "Next step"
    },
    companyInformation: {
      heading: "2. Company information",
      subheading: "Enter the company information for your company",
      fields: {
        companyName: "Company name",
        registrationNumber: "Registration number",
        currency: "Currency",
        country: "Country",
        street: "Street",
        street2: "Street 2",
        city: "City",
        zipcode: "ZIP code",
      },
      checkboxes: {
        terms: "I agree to <termsLink>Terms and Conditions</termsLink> and the <policyLink>Privacy policy</policyLink>",
        marketingInfo: "I'd like to receive marketing information and news about Suppli"
      },
      errors: {
        termsAndConditions: "You must accept the Terms and conditions and Privacy policy"
      },
      buttons: {
        previous: "Back to step 1",
        createAccount: "Create account"
      }
    },
    termsAndConditionsModal: {
      heading: "TERMS AND CONDITIONS",
      buttons: {
        decline: "Decline",
        accept: "Accept"
      },
    },
    signupMain: "Get started",
    googleBtn: "Continue with Google",
    orLabel: "OR",
    emailLabel: "Email",
    passwordLabel: "Password",
    showPasswordLabel: "Show password",
    signupBtn: "Sign up",
    alreadyHaveLabel: "Already have an account?",
    loginLabel: "Log in",
    registerTestemonial: '“Switching to Suppli has tremendously enhanced our warehouse operations. It is the most user-friendly and intuitive solution I have ever used, far surpassing any other WMS in terms of functionality.”',
    clickHereToLogIn: "Click here to log in",
    p1: "Manage your warehouse",
    p2: "with ease and precision.",
    step: "Step",
    receivePromotions: "I'd like to receive marketing information and news about Suppli",
    first_name: "First name *",
    last_name: "Last name *",
    registration_number: "Registration number *",
    company_name: "Company name *",
    country: "Country *",
    street: "Street *",
    street2: "Street 2",
    city: "City *",
    zipcode: "ZIP code *",
    phone_number: "Phone *",
    website: "Website *",
    email: "Email *",
    job_role: "Job role",
    base_currency: "Base currency",
    // roles: ["Business owner", "Inventory operations", "Production and manufacturing", "Purchasing or procurement", "Finance or office administration", "Sales marketing", "Consultant"],
    // businessTypes: ["Wholesale or distribution", "Ecommerce", "Manufacturing or assembly", "Construction or jobsites", "Retail", "Office supplies or internal inventory", "Other"],
    // headings: ["Create new company", "Let's get to know you", "What best fits your business?", "Almost ready!"],
    // buttonTitles: ["Continue", "Continue", "Create company", "Activate account"]
    BusinessOwner: "Business owner",
    InventoryOperations: "Inventory operations",
    ProductionAndManufacturing: "Production and manufacturing",
    PurchasingOrProcurement: "Purchasing or procurement",
    FinanceOrOfficeAdministration: "Finance or office administration",
    SalesMarketing: "Sales marketing",
    Consultant: "Consultant",
    WholesaleOrDistribution: "Wholesale or distribution",
    Ecommerce: "Ecommerce",
    ManufacturingOrAssembly: "Manufacturing or assembly",
    ConstructionOrJobsites: "Construction or jobsites",
    Retail: "Retail",
    OfficeSuppliesOrInternalInventory: "Office supplies or internal inventory",
    Other: "Other",
    CreateNewCompany: "Create new company",
    LetsGetToKnowYou: "Let's get to know you",
    WhatBestFitsYourBusiness: "What best fits your business?",
    AlmostReady: "Almost ready!",
    "Continue": "Continue",
    "CreateCompany": "Create company",
    "ActivateAccount": "Activate account",
    letsPassword: "Let's create your password",
    termsAgree: "I agree with Terms and Conditions",
    password: "Password",
    confirmPassword: "Confirm password",
  },
  forgotPassword: {
    emailInputPage: {
      stage1: {
        heading: "Forgot password?",
        subheading: "Don't worry, we'll send you reset instructions",
        button: "Reset password",
        return: "Back to login"
      },
      stage2: {
        heading: "Check your email",
        subheading: "We sent a password reset link to",
        didntReceive: "Didn't receive the email? Click to resend",
        button: "Resend Email",
        return: "Back to login"
      }
    },
    resetPage: {
      stage1: {
        heading: "Set new password",
        subheading: "Your new password must be different to previously used passwords",
        inputs: {
          original: {
            title: "Password",
            restriction: "Must be at least 8 characters"
          },
          confirmation: {
            title: "Confirm password"
          }
        },
        action: "Reset password"
      },
      stage2: {
        heading: "New password has been saved",
        subheading: [
          "Your password has been successfully reset.",
          "Click below to log in."
        ],
        action: "Log in"
      }
    }
  },
  partnersPage: {
    welcomeBlock: {
      heading: "We are transformational when we are together",
      subheading: "Solutions that make a difference require the best minds. That’s why we partner with leading companies in various industries to share our expertise, learn from each other, and create innovative products that help you as our customers succeed.",
      cta_1: "Become a Partner",
      cta_2: "View Partners"
    },
    partnersPopup: {
      lets: "Let's partner up",
      companyName: "Company name",
      email: "Email",
      phone: "Phone",
      contactPerson: "Contact person",
      getStarted: "Get started"
    },
    whyPartners: {
      heading: "The key to making a difference",
      subheading: "At Suppli, we know that our success is only possible through the strong relationships we have with our partners. With an experienced and knowledgeable community of experts we have, you can rest assured that all areas of your business will see an improvement."
    },
    advantages: {
      first: {
        heading: "Strategic partnerships",
        subheading: "Our partnerships are created with one goal in mind: benefiting our clients. We want to help you reach your goals, and we're here to help you every step of the way."
      },
      second: {
        heading: "Complex solutions",
        subheading: "Relationships with influential global companies allow us to offer our clients an even wider range of solutions with more functionality."
      },
      third: {
        heading: "In-depth knowledge",
        subheading: "With our partners, we gain access to a wider range of expertise, ideas, and new perspectives on old problems."
      },
      fourth: {
        heading: "Exclusive deals & value",
        subheading: "Our partnerships help you save money and reinvest back into your business while gaining more."
      }
    },
    companies: {
      heading_1: "Partners",
      heading_2: "Accounting companies",
      dropdown: {
        heading: "Partners by country",
        option_all: "All"
      },
      list: {
        woocommerce: {
          heading: "WooCommerce",
          subheadings: [
            "WooCommerce is a great eCommerce platform that enables you to quickly set up an online store and can be customized to suit the specific needs of any business.",
            "Integrating your WooCommerce store with Suppli WMS will help take the guesswork out of stock control and order fulfillment by automatically syncing products in real-time.",
            "Once a customer places an order, it's immediately sent to the warehouse where it's prepared and dispatched as soon as possible. If an item is out of stock in WMS, it will show as out of stock on your website - meaning no more surprises for customers (or yourself!)."
          ]
        },
        hubspot: {
          heading: "Hubspot",
          subheadings: [
            "Hubspot provides an excellent CRM platform for sales teams, with automation, reporting, and sales funnel management tools.",
            "Integrating your Suppli WMS with Hubspot CRM will keep your business one step ahead by automating repetitive manual processes, improving response times, and making your team more efficient. Moreover, you will be able to keep track of every customer interaction and use this data to improve your business processes. ",
            "The two systems working together will allow you to provide a higher level of service and make more informed decisions about your business."
          ]
        },
        poweroffice_go: {
          heading: "PowerOffice GO",
          subheadings: [
            "PowerOffice Go is a complete accounting system that offers all the features and functionality you need to manage your business finances (sales and expenses, invoices and estimates, reports, and more).",
            "Having your Suppli WMS integrated with PowerOffice GO accounting software is a great way to streamline operations and avoid human error. ",
            "We believe that you as our customer shouldn't have to waste time manually inputting information like customers, products, amounts, and prices - all of which can be found in the WMS. When you press \"complete\""
          ]
        },
        rivals: {
          heading: "Rivals Software",
          subheadings: [
            "Rivals Software is an innovative software development company that specializes in developing ready-to-deploy and customized software solutions, as well as state-of-the-art websites. The company serves a wide range of industries, including healthcare, finance, education, and retail, among others.",
            "Rivals Software's mission is to help businesses improve their operations and increase their productivity through the use of cutting-edge software technologies. The company has a team of highly skilled software developers and engineers dedicated to providing effective solutions to businesses of all sizes.",
            ""
          ]
        }
      }
    },
    footerCTA: {
      heading: "Ready to get connected?",
      subheading: "We are excited to see how we can help you succeed",
      cta: "Connect our team"
    }
  },
  dashboard: {
    sidebar: {
      dashboard: "Dashboard",
      calendar: "Calendar",

      products: "Products",
      allProducts: "All Products",
      productionOrder: "Bill of Materials",

      purchasing: "Purchasing",
      purchases: "Purchases",
      receive: "Receive",
      picking: "Picking",

      inventory: "Inventory",
      quickPurchaseOrder: "Quick Purchase Order",

      stock: "Stock",
      adjustments: "Adjustments",
      collections: "Collections",
      transfers: "Transfers",

      orders: "Sale orders",
      placeInOrder: "Place In Order",

      customers: "Customers",
      newCustomer: "New customer",
      customerList: "Customer list",
      customerGroup: "Groups",

      suppliers: "Suppliers",

      userLogs: "User Logs",
      settings: "Settings"
    },
    navbar: {
      languages: {
        english: "English",
        norwegian: "Norwegian"
      },
      totalStat: {
        totalRevenue: "TOTAL REVENUE",
        totalInStock: "TOTAL IN STOCK",
        thisMonth: "this month"
      },
      notifications: {
        yourNotifications: "Your Notifications"
      },
      userMenu: {
        myAccount: "My account",
        companyInfo: "Company information",
        subscription: "Subscription",
        action: "Log out"
      }
    },
    dashboard: {
      stats: {
        revenue: {
          heading: "Revenue", // auto-uppercase
          chart: {
            expenses: "Expenses",
            income: "Income"
          },
          tabs: ["Monthly", "Yearly"]
        },
        bestSellingProducts: {
          heading: "Best selling products",
          chip: "this month",
          notFound: "No statistics found"
        },
        feed: {
          heading: "Feed",
          actionConnector: "has been",
          actions: {
            "update": "updated",
            "create": "created",
            "delete": "deleted",
            "receive": "received",
            "adjustment": "adjusted",
            "transfer": "transferred",
            update_selling_price: "updated (selling price)",
          }
        },
        restocking: {
          heading: "Restocking",
          columns: ["Product", "Qty"],
          orderButton: "Order now",
          notFound: "Nothing to restock"
        }
      },
      upcomingShipments: {
        heading: "Upcoming shipments",
        orderNaming: "Order", // order id will be joined automatically
        orderDatePrefix: "Today",
        orderTimePrefix: "at", // for example: "Today *at* 9:00 am"
        dropdownNaming: "All locations",
        tooltip: "Start receiving",
        noUpcomingShipmentsFound: "You don't have any upcoming shipments"
      }
    }
  },
  bom: {
    produceModal: {
      heading: "Produce",
      quantityCanProduced: "Quantity that can be produced",
      of: "of",
      table: {
        components: "Components",
        columns: ["Code", "Name", "Quantity", "Outgoing location", "Used quantity", "Action"],
        noComponents: "No components",
      },
      actions: {
        addLocation: "Add location",
      },
      fields: {
        quantity: "Quantity",
      },
      dropdowns: {
        location: {
          label: "Put produced to",
          placeholder: "Select location"
        },
      },
      responses: {
        error: "An error occurred while producing a product."
      },
      buttons: {
        continue: "Produce",
        cancel: "Cancel",
        addSerialNumbers: "Add Serial Numbers",
        addBatchNumbers: "Add Batch Numbers"
      },
      validationErrors: {
        maxAvailableInStock: "The specified quantity exceeds the maximum available units in stock. Maximum is ",
        maxProducible: "The specified quantity exceeds the maximum producible units based on component stocks. Maximum is ",
        serialNumbersRequired: "Serial numbers are required",
        serialNumbersMatchQuantity: "Serial numbers quantity must match the produced quantity",
        serialNumbersCount: "Serial numbers count cannot be greater than produced quantity",
        batchNumbersRequired: "Batch numbers are required",
        batchNumbersCount: "Batch numbers count cannot be greater than produced quantity"
      },
      modals: {
        serialNumbersModal: {
          heading: "Serial Numbers",
          buttons: {
            addSerialNumber: "Add Serial Number",
            cancel: "Cancel",
            attach: "Attach"
          },
          fields: {
            serialNumber: {
              placeholder: "Serial Number"
            }
          }
        },
        batchNumbersModal: {
          heading: "Batch numbers",
          buttons: {
            addBatchNumber: "Add Batch Number",
            cancel: "Cancel",
            attach: "Attach"
          },
          fields: {
            batchNumber: {
              placeholder: "Batch Number"
            },
            expirationDate: {
              placeholder: "Expiration Date"
            }
          }
        }
      }
    },
    produceModalSuccess: {
      heading: "Success",
      subheading: "Your products have been produced.",
      button: "Continue"
    },
    mutation: {
      modals: {
        selectComponent: "Select component",
        editComponent: "Edit component",
        addNewComponent: "Add new component",
        component: {
          label: "Component",
          placeholder: "Select component"
        },
        quantity: "Quantity",
        buttons: {
          save: "Save",
          cancel: "Cancel",
        },
        errors: {
          duplicateComponents: "Duplicate components are not allowed"
        }
      },
      addComponents: "Add components",
      componentName: "Component name",
      purchasePrice: "Purchase price",
      totalPrice: "Total price",
      quantityCanProduced: "Quantity that can be produced",
      inStock: "In stock",
      action: "Action",
      selectProduct: "Select product",
      fields: {
        bomName: "BOM Name",
      },
      dropdowns: {
        productName: {
          label: "Product name",
          placeholder: "Select product"
        },
      },
      table: {
        components: "Components",
        addNewComponent: "Add new component",
        columns: ["Name", "Quantity", "Purchase price", "Total price", "In stock", "Action"],
        noComponents: "No components",
      },
      validationErrors: {
        zeroComponents: "You must add at least one component",
      },
      buttons: {
        create: "Create bill of material",
        save: "Save",
      }
    },
    creation: {
      heading: "Create new bill of material",
      responses: {
        success: "Bill of material has been successfully created.",
        error: "An error occurred while creating a bill of material."
      },
      buttons: {
        create: "Create bill of material",
      }
    },
    editing: {
      heading: "Edit",
      responses: {
        success: "Bill of material has been successfully updated.",
        error: "An error occurred while updating a bill of material."
      },
      buttons: {
        save: "Save",
      }
    },
    details: {
      actions: {
        produce: "Produce",
        edit: "Edit",
        delete: "Delete"
      },
      producingHistory: {
        heading: "Production history",
        table: {
          columns: ["Location", "Produced quantity", "Date", "Serial/Batch numbers"],
          subtable: {
            columns: ["Component", "Used quantity", "Outgoing location"]
          },
          buttons: {
            sn: "SN",
            bn: "BN"
          }
        },
        tip: {
          noHistoryFound: "No production history found"
        }
      }
    },
    main: {
      "All": "All",
      "ComponentName": "Component Name / Code",
      producingHistory: "Production history",
      table: {
        columns: ["BOM name", "Product name", "Category", "Components", "In stock", "Produced", "Actions"],
        subtable: {
          columns: ["Component name", "Quantity", "Purchase price", "Total price", "In stock"]
        },
        titleDeclinations: ["item", "items", "items"],
        actions: {
          produce: "Produce",
          edit: "Edit",
          delete: "Delete"
        }
      },
      "ImportSuccess": "Import product successfully",
      "ImportFailed": "Failed import product:",
      "ExportFailed": "Failed to export product:",
      "ExportSuccess": "Product successfully exported",
      "ConfirmDeletion": "Confirm deletion",
      "YouCannotChange": "You cannot change your mind once deletion has been completed.",
      "Cancel": "Cancel",
      "Delete": "Delete",
      "BOMS": "BOMS:",
      "ExportComponents": "Export components",
      "Export": "Export",
      "Heading": "Heading",
    },
    deletionModal: {
      heading: "Confirm deletion",
      subheading: "Are you sure you want to delete this bill of material: ",
      buttons: {
        cancel: "Cancel",
        delete: "Delete"
      },
      responses: {
        success: "Bill of material has been successfully deleted.",
        error: "An error occurred while deleting a bill of material."
      }
    },
    producingHistory: {
      heading: "Production history",
      links: {
        bomList: "BOM products list"
      },
      table: {
        columns: ["BOM name", "Product name", "Produced quantity", "Location", "Date", "Serial/Batch numbers"],
        subtable: {
          columns: ["Component", "Used quantity", "Outgoing location"]
        },
        buttons: {
          sn: "SN",
          bn: "BN"
        }
      }
    }
  },
  products: {
    allProducts: {
      table: {
        titleDeclinations: ["item", "items", "items"], // 1 -> item, 2 -> items, 5 -> items
        columns: ["Product name", "Type", "Category", "Selling price", "In stock", "Components", "Action"],
        isService: "Service",
        isProduct: "Product",
      },
      subtable: {
        columns: ["Variant code", "Variant name", "Selling price", "In stock"],
      },
      modals: {
        deletion: { // TODO: [~nekear]
          heading: "Confirm deletion",
          subheading: "You can not change your mind once deleted has been completed.",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Product has been successfully deleted.",
            error: "An error occurred while deleting a product.",
          }
        },
        exporting: {
          title: "Export Products",
          dropdown: {
            label: "Heading",
            options: {
              productName: "Product name",
              productCode: "Product code",
              barcode: "Barcode",
              unit: "Unit",
              group: "Group",
              defaultLocation: "Default location",
              primarySupplier: "Primary supplier",
              defaultTax: "Default tax",
              purchasePrice: "Purchase price",
              sellingPrice: "Selling price",
              extraCost: "Extra cost",
            }
          },
          okText: "Export"
        },
        barcodeLabel: {
          heading: "Barcode label",
          fields: {
            barcode: {
              label: "Barcode",
              placeholder: "Enter barcode to use"
            },
            standard: {
              label: "Standard",
            }
          },
          errors: {
            invalidStandard: "Unable to generate barcode in the selected standard",
          },
          buttons: {
            cancel: "Cancel",
            print: "Print"
          }
        }
      },

      filters: {
        import: {
          title: "Import products"
        },
        dropdowns: {
          category: {
            label: "Category"
          },
          components: {
            label: "Components",
            options: ["No", "Yes"] // TODO: [+nekear]
          },
          types: {
            label: "Type",
            options: ["Product", "Service"]
          }
        },
        ranges: {
          sellingPrice: "Selling price",
          quantityInStock: "Quantity in stock",
        }
      },

      toasts: {
        deletion: {
          success: "Delete product successfully",
          error: "Failed to delete product" // + error
        },
        exporting: {
          success: "Export product successfully",
          error: "Failed to export product" // + error
        }
      }
    },
    newProduct: {
      heading: "Create new product",
      templates: {
        dropdown: {
          default: "Templates",
          nothingFound: "No templates",
        }
      },
      modals: {
        saveTemplate: {
          heading: "Save Template",
          fields: {
            name: "Template name"
          },
          buttons: {
            save: "Save"
          }
        },

        batchNumbers: {
          heading: "Enter batch numbers",
          fields: {
            batchNumber: {
              placeholder: "Batch number"
            },
            expirationDate: {
              placeholder: "Expiration date"
            }
          },
          alerts: {
            overflowBatchNumbers: "Batch numbers quantity cannot be greater than picked quantity",
          },
          buttons: {
            save: "Save",
            addBatchNumber: "Add batch number"
          }
        },

        serialNumbers: {
          heading: "Enter serial numbers",
          fields: {
            serialNumber: {
              placeholder: "Serial number"
            }
          },
          alerts: {
            overflowSerialNumbers: "Serial numbers quantity cannot be greater than picked quantity"
          },
          buttons: {
            save: "Save",
            addSerialNumber: "Add serial number"
          }
        }
      },

    },

    viewEditProduct: {
      heading: "Product details",
      isService: "Service",
      isVariant: "Variant",
      modals: {
        deletion: {
          heading: "Confirm deletion",
          subheading: "You can not change your mind once deleted has been completed.",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Delete product successfully",
            error: "Failed to delete product"
          }
        }
      },
      tabs: {
        information: {
          heading: "Information",
        },
        translations: {
          heading: "Translations",
          table: {
              columns: ["Language", "Translated name", "Translated description", "Actions"],
              buttons: {
                  addLanguage: "Add language"
              }
          },
          modals: {
              mutation: {
                  editing: {
                      heading: "Edit translation",
                      responses: {
                          success: "Translation edited successfully",
                          error: "Error editing translation"
                      }
                  },
                  addition: {
                      heading: "Add translation",
                      responses: {
                          success: "Translation added successfully",
                          error: "Error adding translation"
                      }
                  },

                  fields: {
                      language: {
                          label: "Language",
                          placeholder: "Select language"
                      },
                      name: {
                          label: "Translated name",
                          placeholder: ""
                      },
                      description: {
                          label: "Translated description",
                          placeholder: ""
                      }
                  },
                  buttons: {
                      save: "Save"
                  }
              },
              deletion: {
                  heading: "Confirm deletion",
                  subHeading: "Are you sure you want to delete",
                  buttons: {
                      delete: "Delete",
                      cancel: "Cancel"
                  },
                  responses: {
                      error: "Deletion error",
                      success: "Deleted successfully"
                  }
              }
          }
        },
        prices: {
          heading: "Prices",
          table: {
            columns: {
              purchasingPrice: "Purchase price",
              sellingPrice: "Selling price",
              date: "Date",
              author: "Author",
              origin: "Origin"
            },
            authorUnknown: "Unknown",
            origins: {
              "adjustment": "Adjustment",
              "purchase_order": "Purchase Order",
              "sale_order": "Sale Order",
              "product": "Product",
              "manual": "Manually"
            }
          }
        },
        locations: {
          heading: "Locations",
          table: {
            columns: ["Location", "Quantity in stock", "Min. inventory quantity", "Min. purchase quantity", "Min. sale quantity"]
          },
          buttons: {
            save: "Save changes"
          },
          responses: {
            save: {
              success: "Locations have been updated successfully!",
              error: "An error occurred while updating locations..."
            }
          }
        },
        numbers: {
          heading: "Serial/Batch",
          serialNumbers: {
            heading: "Serial numbers",
            table: {
              columns: ["Number", "Date", "Actions"]
            },
            filters: {
              additionDate: {
                label: "Addition date",
                placeholder: "Select date"
              }
            },
            modals: {
              update: {
                heading: "Edit serial number",
                fields: {
                  serialNumber: {
                    label: "Serial number",
                    placeholder: "Enter serial number"
                  }
                },
                buttons: {
                  cancel: "Cancel",
                  save: "Save"
                },
                responses: {
                  success: "Serial number has been updated successfully",
                  error: "An error occurred while updating serial number"
                }
              },
            }
          },
          batchNumbers: {
            heading: "Batch numbers",
            table: {
              columns: ["Number", "Date", "Expiration date", "Actions"]
            },
            filters: {
              additionDate: {
                label: "Addition date",
                placeholder: "Select date range"
              },
              expirationDate: {
                label: "Expiration date",
                placeholder: "Select date range"
              },
            },
            modals: {
              update: {
                heading: "Edit batch number",
                fields: {
                  batchNumber: {
                    label: "Batch number",
                    placeholder: "Enter batch number"
                  },
                  expirationDate: {
                    label: "Expiration date",
                    placeholder: "Select expiration date"
                  }
                },
                buttons: {
                  cancel: "Cancel",
                  save: "Save"
                },
                responses: {
                  success: "Batch number has been updated successfully",
                  error: "An error occurred while updating batch number"
                }
              },
            }
          },
          header: {
            search: {
              placeholder: "Search serial number"
            },
            filters: {
              heading: "Filters"
            }
          }
        },
        purchaseOrder: {
          heading: "PO",
          table: {
            columns: ["Order code", "Supplier", "Quantity", "Receive", "Order date"],
            receiveStatuses: {
              notReceived: "Not received",
              partiallyReceived: "In progress",
              received: "Received"
            }
          }
        },
        saleOrder: {
          heading: "SO",
          table: {
            columns: ["Order code", "Customer", "Quantity", "Invoicing", "Order date"],
            invoicingStatuses: {
              notInvoiced: "Not invoiced",
              invoiced: "Invoiced"
            }
          }
        }
      },
      notFound: "Product not found"
    },

    createEditProduct: {
      disabledDueToService: "Service",
      responses: {
        creation: {
            success: "Product has been created successfully",
            error: "An error occurred while creating product"
        },
        update: {
            success: "Product has been updated successfully",
            error: "An error occurred while updating product"
        }
      },
      validation: {
        serialNumbers: "Serial numbers are filled incorrectly!",
        batchNumbers: "Batch numbers are filled incorrectly!",
      },
      variants: {
        imageUploading: {
          buttonText: "Add image"
        },
        fields: {
          name: {
            label: "Variant option",
            placeholder: "Color, size"
          },
          value: {
            label: "Variant option values"
          }
        },
        buttons: {
          addNewVariant: "Add new variant"
        },
        notFound: "Not variants added",
      },

      additionalPrices: {
        fields: {
          sellingPrice: {
            label: "Selling price"
          },
          priceList: {
            label: "Price list",
            placeholder: "Select price list"
          }
        },
        buttons: {
          addNewPrice: "Add new price"
        },
        notFound: "Not additional prices added",
      },

      attachment: {
        imagePrompt: "Drag image here",
        defaultImage: "Default image",
        setAsDefault: "Set as default"
      },

      modals: {
        deletionVariant: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete this variant: ",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
        },
        variant: {
          heading: "Add new variant",
          titles: {
            weightAndSize: "Weight & Size",
            prices: "Prices",
            inventory: "Inventory",
            options: "Options",
            attachments: "Image & Description"
          },
          fields: {
            productName: "Product name",
            productCode: "Product code",
            inStock: "In stock",
            barcode: "Barcode",
            purchasePrice: "Purchase price",
            sellingPrice: "Selling price",
            extraCost: "Extra cost",
            dynamicPricePrompt: "After creation purchasing price can only be changed through orders",
            minPurchaseQTY: "Min. purchase quantity",
            minSaleQTY: "Min. sale quantity",
            weight: "Weight",
            CBM: "CBM",
            width: "Width",
            height: "Height",
            length: "Length",
            description: "Description",
          },
          dropdowns: {
            defaultLocation: {
              label: "Default location",
              placeholder: "Select location"
            },
            supplier: {
              label: "Supplier",
              placeholder: "Select supplier"
            },
            tax: {
              label: "Tax",
              placeholder: "Select tax"
            },
          },
          checkboxes: {
            batchNumber: {
              label: "Batch Number",
              tip: "Check this option if this product is produced by batches, transactions such as stocks received and deliveries will be done in batches."
            },
            serialNumber: {
              label: "Serial Number",
              tip: "Check this option if this product has serial numbers. Transactions such as stocks receipt and deliveries will be done with serial number."
            },
            component: {
              label: "Component",
              tip: "Can this product be used as a component?"
            }
          },
          buttons: {
            addSerialNumbers: "Add serial numbers",
            addBatchNumbers: "Add batch numbers",
            create: "Create",
            save: "Save"
          }
        },
        unit: {
          createButton: "Create",
          creationModal: {
            heading: "Create unit",
            nameField: {
              label: "Name",
              placeholder: "Kg, pcs, liters"
            },
            createButton: "Create and apply",
            responses: {
              success: "Unit has been created successfully",
              error: "An error occurred while creating unit"
            }
          },
          deletionModal: {
            heading: "Confirm deletion",
            subheading: "Are you sure you want to delete this unit?",
            cancel: "Cancel",
            delete: "Delete",
            responses: {
              success: "Unit has been successfully deleted",
              error: "An error occurred while deleting unit"
            }
          }
        },

        category: {
          createButton: "Create",
          creationModal: {
            heading: "Create category",
            fields: {
              code: {
                label: "Code"
              },
              name: {
                label: "Name",
              },
              isService: {
                label: "Service"
              },
            },
            createButton: "Create and apply",
            responses: {
              success: "Category has been created successfully",
              idNotUnique: "Category id should be unique",
              error: "An error occurred while creating unit"
            }
          },
        },

        location: {
          createButton: "Create",
          creationModal: {
            heading: "Create location",
            locationField: {
              label: "Location name"
            },
            sectionsCheckbox: {
              title: "Sections",
              tip: "..."
            },
            sectionField: {
              label: "Section name"
            },
            createButton: "Create and apply",
            responses: {
              success: "Location has been created successfully",
              error: "An error occurred while creating location"
            }
          },
        },

        supplier: {
          createButton: "Create",
          creationModal: {
            heading: "Create supplier",
            fields: {
              supplierName: "Supplier name",
              supplierCode: "Supplier code",
              name: "Name",
              country: "Country",
              street: "Street",
              street2: "Street 2",
              zipcode: "Zipcode",
              city: "City",
              email: "Email",
              phone: "Phone",
            },
            dropdowns: {
              country: {
                label: "Country",
                placeholder: "Select country",
              },
              group: {
                label: "Group",
                placeholder: "Select group",
              },
              paymentTerm: {
                label: "Payment term",
                placeholder: "Select payment term",
              },
              defaultCurrency: {
                label: "Default currency",
                placeholder: "Select currency",
              },
              shipmentTerm: {
                label: "Shipment term",
                placeholder: "Select shipment term",
              },
              shipmentMethod: {
                label: "Shipment method",
                placeholder: "Select shipment method",
              },
            },

            categories: {
              address: "Address",
              options: "Options"
            },

            createButton: "Create",

            responses: {
              success: "Supplier has been created successfully",
              error: "An error occurred while creating supplier"
            },
          }
        },

        priceList: {
          createButton: "Create",
          creationModal: {
            heading: "Create price list",
            fields: {
              name: {
                label: "Price list name"
              },
              code: {
                label: "Price list code"
              },
            },
            dropdowns: {
              currency: {
                label: "Currency",
                placeholder: "Select currency"
              }
            },
            createButton: "Create and apply",
            responses: {
              success: "Price list has been created successfully",
              error: "An error occurred while creating price list"
            }
          },
        },

        currency: {
          createButton: "Create",
          creationModal: {
            heading: "Create new currency",
            dropdowns: {
              code: {
                label: "Currency code",
                placeholder: "Select currency code"
              }
            },
            symbol: {
              label: "Symbol"
            },
            createButton: "Create and apply",
            responses: {
              success: "Currency has been created successfully",
              error: "An error occurred while creating currency"
            }
          },
        },

        imagesUploading: {
          heading: "Upload image",
          addImage: "Add image",
          close: "Close"
        },

        templates: {
          buttons: {
            create: "Create product and save template",
            manage: "Manage template",
            delete: "Delete template",
            save: "Save changes"
          },

          creationModal: {
            heading: "Save template",
            fields: {
              name: "Template name"
            },
            buttons: {
              save: "Save",
            },
            responses: {
              success: "Template has been saved and applied",
              error: "An error occurred while saving the template"
            }
          },

          deletionModal: {
            heading: "Confirm deletion",
            subheading: "Are you sure you want to delete",
            templateInterpretation: "Template",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Template has been deleted!",
              error: "An error occurred while deleting the template!"
            }
          },

          savingProcess: {
            responses: {
              success: "Template has been updated!",
              error: "An error occurred while updating the template!"
            }
          }
        },

        tax: {
          dropdown: {
            label: "Tax rate",
            placeholder: "Select tax rate",
            buttons: {
              create: "Create"
            }
          },
          modals: {
            creation: {
              heading: "Create tax",
              taxName: {
                label: "Tax name"
              },
              taxRate: {
                label: "Tax rate"
              },
              buttons: {
                create: "Create and apply"
              },
              responses: {
                success: "Tax has been created successfully!",
                error: "An error occurred while creating tax..."
              }
            }
          }
        }
      },

      buttons: {
        create: "Create product",
        save: "Save",
        cancel: "Cancel"
      }
    },

    general: {
      categories: {
        general: "General",
        options: "Options",
        attachments: "Image & Description",
        prices: "Prices",
        weights: "Weight & Size",
        inventory: "Inventory",
        variants: "Variants",
      },
      fields: { // TODO: [~nekear]
        isService: {
          label: "Service",
          obtainedFromCategory: "Inherited from category"
        },
        productName: "Product name",
        productCode: "Product code",
        barcode: "Barcode",
        unit: {
          label: "Unit",
          placeholder: "Select unit"
        },
        category: {
          label: "Category",
          placeholder: "Select category",
          serviceBadge: "Service"
        },
        defaultLocation: {
          label: "Default location",
          placeholder: "Select location",
        },
        primarySupplier: {
          label: "Primary supplier",
          placeholder: "Select supplier"
        },
        defaultTax: {
          label: "Default TAX",
          placeholder: "Select tax"
        },

        attachmentDescription: "Description",

        rfid: {
          title: "RFID",
          tip: "..."
        },
        batchNumber: {
          title: "Batch number",
          tip: "Check this option if this product is produced by batches, transactions such as stocks received and deliveries will be done in batches."
        },
        serialNumber: {
          title: "Serial number",
          tip: "Check this option if this product has serial numbers. Transactions such as stocks receipt and deliveries will be done with serial number."
        },
        components: {
          title: "Component", // TODO: from "Components" to "Component"
          tip: "Can this product be used as a component?" // TODO: updated
        },
        variants: {
          title: "Variants",
          tip: "Does this product have variants? You will not be able to add Variants later if you do not select this option when creating new product."
        },

        purchasePrice: "Purchase price",
        sellingPrice: "Selling price",
        extraCost: "Extra cost",
        dynamicPricePrompt: "After creation prices can only be changed through orders",
        noVariants: "No variants",
        addVariant: "Add variant",
        newVariant: "New",
        deletedVariant: "Deleted",

        currency: {
          label: "Currency",
          placeholder: "Select currency"
        },

        weight: "Weight",
        cbm: "CBM",
        width: "Width",
        height: "Height",
        length: "Length/Depth",

        inStock: "In stock",
        invQty: "Min. inventory quantity",
        purchaseQty: "Min. purchase quantity",
        saleQty: "Min. sale quantity"
      },

      buttons: {
        addSerialNumbers: "Add serial numbers",
        addBatchNumbers: "Add batch numbers",
      }
    },

    modals: {
      deletion: {
        heading: "Confirm deletion",
        subheading: "Are you sure you want to delete",
        buttons: {
          cancel: "Cancel",
          delete: "Delete"
        },
        responses: {
          success: "Product has been deleted!",
          error: "Error occurred when deleting the product!"
        }
      }
    }
  },

  inventory: {
    stock: {
      redirect: "Stock count reports",
      table: {
        titleDeclinations: ["item", "items", "items"], // 1 -> item, 2 -> items, 5 -> items
        columns: ["Product name", "Category", "Purchase price", "In Stock", "Total value", "Action"]
      },
      subtable: {
        columns: {
          locationName: "Location",
          sectionName: "Section",
          sectionSector: "Sector",
          sectionRow: "Row",
          sectionShelfHeight: "Shelf",
          inStock: "Quantity"
        }
      },
      modals: {
        filter: {
          fields: {
            category: { // TODO: [-nekear] removed dropdowns' all
              label: "Category"
            },
            purchasePrice: {
              label: "Purchase price"
            },
            inStock: {
              label: "In Stock"
            }
          }
        },
        export: {
          title: "Export stock",
          fields: {
            historyReversionDate: {
                label: "Get stock levels for a date",
                placeholder: "Select date"
            },
            export: {
              label: "Export",
              dropdown: {
                chooseAll: "All",
                chooseCustom: "Custom amount", // TODO: [+nekear] changed from chooseFirst to chooseFirst
                chooseSelected: "Selected"
              }
            },
            customNumberField: {
              placeholder: "Enter how many products we should export" // TODO: [+nekear]
            },
            exportTo: {
              label: "Export to",
              options: ["Excel", "PDF"]
            }
          },
          exportButton: "Export"
        }
      }
    },
    stockCount: {
      main: {
        redirect: "Products in stock",
        status: ["New", "Cancelled", "Declined", "Approved"],
        table: {
          columns: ["Report code", "Location", "Date", "Worker", "Status"]
        },
        subtable: {
          columns: ["Product", "Location", "Quantity in stock", "Actual quantity", "Difference"],
          buttons: {
            approve: "Approve report",
            decline: "Decline report"
          }
        },

        filters: {
          location: {
            label: "Location"
          },
          date: {
            label: "Date",
            placeholder: "Select period"
          },
          worker: {
            label: "Worker"
          },
          status: {
            label: "Status",
          }
        },

        export: {
          columns: {
            stock_count_id: "Report id",
            worker_first_name: "Worker first name",
            worker_last_name: "Worker last name",
            store: "Store",
            section: "Section",
            status: "Status",
            decline_comment: "Decline comment",
            cancel_comment: "Cancel comment",
            date: "Date",
            product_id: "Product ID",
            product_name: "Product name",
            counted_quantity: "Counted quantity",
            system_quantity: "System quantity",
            reviewer_first_name: "Reviewer first name",
            reviewer_last_name: "Reviewer last name",
          },
          responses: {
            error: "Unable to export stock counts!"
          }
        }
      },
      modals: {
        approve: {
          heading: "Approve stock count report",
          subheading: "Are you sure you want to approve <bold>{{reportId}}</bold> by {{worker}}?",
          buttons: {
            cancel: "Cancel",
            confirm: "Confirm"
          },
          responses: {
            success: "Stock count has been approved!",
            error: "An error occurred while approving stock count!"
          }
        },
        decline: {
          heading: "Decline stock count report",
          subheading: "Are you sure you want to decline <bold>{{reportId}}</bold> by {{worker}}?",
          additionalText: "Please describe the reason below",
          placeholder: "Describe the reason",
          buttons: {
            cancel: "Cancel",
            confirm: "Confirm"
          },
          responses: {
            success: "Stock count has been declined!",
            error: "An error occurred while declining stock count!"
          }
        },
        merge: {
          heading: "Merge stock counts",
          positive: {
            subheading: "Are you sure you want to merge <bold>{{reports}}</bold>?",
          },
          negative: {
            subheading: "The following reports <bold>({{reports}})</bold> have conflicts.",
            additionalText: "Would you like to merge them?",
          },
          table: {
            columns: ["Report id", "Worker", "Quantity in stock", "Counted quantity"]
          },
          buttons: {
            cancel: "Cancel",
            submit: "Submit"
          },
          responses: {
            success: "Stock counts have been merged!",
            error: "An error occurred while merging stock counts!"
          }
        },
      }
    },
    adjustments: {
      table: {
        titleDeclinations: ["item", "items", "items"], // 1 -> item, 2 -> items, 5 -> items
        columns: ["Product name/Adj. code", "Location", "User", "Adjustments", "Remarks", "Date", "Action"], // TODO: [~nekear] changed first column name
        adjustmentTypes: {
          quantity: "Quantity",
          costPrice: "Purchasing Price"
        }
      },

      createViewEditAdjustments: {
        fieldsGeneral: {
          product: {
            label: "Product",
            placeholder: "Select product"
          },
          location: {
            label: "Location",
            placeholder: "Select location",
            disableReason: "Select product first"
          },
          date: {
            label: "Date"
          },
          adjustment: {
            label: "Adjustments", // TODO: [+nekear]
            selectQuantity: "Quantity",
            selectCostPrice: "Purchasing price"
          },
          remarks: {
            label: "Remarks"
          },
          sellingPriceChange: {
            label: "Updated selling price",
          }
        },

        // Those appear only if Quantity is selected
        fieldsCreateQuantityAdjustment: {
          availableQuantity: { // TODO: [+nekear] changed field name [-nekear] removed placeholders
            label: "Available quantity"
          },
          actualQuantity: {
            label: "Actual quantity"
          },
          availableQuantityDifference: {
            label: "Available quantity (difference)"
          }
        },

        // Those appear only if Cost price is selected
        fieldsCreateCostPriceAdjustment: { // TODO: [-nekear] removed placeholders
          costPrice: {
            label: "Purchasing price"
          },
          actualCostPrice: {
            label: "Actual purchasing price"
          },
          actualCostPriceDifference: {
            label: "Actual purchasing price (difference)"
          }
        }
      },

      // TODO: [+nekear] added those titles
      createAdjustment: {
        heading: "Create new adjustment",
        button: "Save", // TODO: [+nekear] moved button from block above
        responses: {
          success: "Adjustment has been saved successfully!",
          error: "An error occurred while creating adjustment!"
        }
      },

      editAdjustment: {
        heading: "Adjustment",
        button: "Save changes", // TODO: [+nekear] moved button from block above
        notFound: "No such adjustment found!",
        responses: {
          success: "Adjustment has been saved successfully!",
          error: "An error occurred while saving adjustment!"
        }
      },

      viewAdjustment: {
        heading: "Adjustment",
      },

      notFound: "Adjustment not found",

      modals: {
        filter: {
          fields: { // TODO: [-nekear] Removed placeholders here and dropdowns' "all" translations
            product: {
              label: "Product"
            },
            adjustment: {
              label: "Adjustment"
            },
            location: {
              label: "Location"
            },
            user: {
              label: "User"
            },
            date: {
              label: "Date",
              placeholder: "Select period"
            }
          }
        },
        confirmDelete: {
          label: "Confirm deletion",
          hint: "Are you sure you want to delete",
          deletionItem: "Stock adjustment", // TODO: [+nekear]
          buttons: { // TODO: [+nekear]
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Adjustment has been deleted!", // TODO: [+nekear]
            error: "Error occurred when deleting the adjustment!" // TODO: [+nekear]
          }
        },
        sellingPriceSuggestion: {
          heading: "Update selling price",
          prompt: "Would you also like to update the selling price after the new cost price is saved? <br/> <b>You can always update the selling price manually in the product card.</b>",
          currentSellingPrice: "Current selling price: {{current}}",
          options: {
            addedPrice: "Update selling price based on the added price difference",
            percent: "Update selling price based on the adjusted {{percent}}%"
          },
          newPrice: "New price",
          buttons: {
            decline: "Decline",
            update: "Update"
          }
        }
      }
    },

    collections: {
      table: {
        titleDeclinations: ["item", "items", "items"], // 1 -> item, 2 -> items, 5 -> items
        columns: ["Collection code", "Name", "Products", "Action"],
      },
      subtable: {
        columns: ["Product code", "Product name", "Quantity"],
      },
      collectionMutation: {
        editing: {
          heading: "Edit collection",
          responses: {
            success: "Collection updated successfully",
            error: "Error updating collection",
          },
        },
        creation: {
          heading: "Create new collection",
          responses: {
            success: "Collection created successfully",
            error: "Error creating collection",
          },
        },
        modals: {
          mutateProduct: {
            editing: {
              heading: "Edit product",
            },
            addition: {
              heading: "Add product",
            },
            fields: {
              product: {
                label: "Product",
                placeholder: "Select product",
              },
              quantity: {
                label: "Quantity",
                placeholder: "0.00",
              },
            },
            saveButton: "Save",
          },
        },

        fields: {
          code: {
            label: "Collection code",
            placeholder: "Enter code",
          },
          name: {
            label: "Collection name",
            placeholder: "Enter collection name",
          },
          barcode: {
            label: "Barcode",
            placeholder: "Enter barcode",
          },
          products: {
            header: "Products",
            placeholder: "No products",
            addButton: "Add product",
            validationErrors: {
              zeroProducts: "No products added",
            },
            table: {
              columns: ["Code", "Product name", "Quantity", "Actions"],
            },
          },
        },

        saveButton: "Save",
      },
      modals: {
        deletion: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete collection",
          buttons: {
            cancel: "Cancel",
            delete: "Delete",
          },
          responses: {
            success: "Collection deleted successfully",
            error: "Error deleting collection",
          },
        },
      },
    },

    stockTransfers: {
      table: {
        titleDeclinations: ["item", "items", "items"], // 1 -> item, 2 -> items, 5 -> items
        columns: ["Product name/Transfer ID", "From location", "To location", "User", "Remarks", "Date", "Action"]
      },

      modals: {
        filter: {
          fields: {
            product: {
              label: "Product name"
            },
            user: {
              label: "User"
            },
            fromLocation: {
              label: "From location",
            },
            toLocation: {
              label: "To location",
            },
            date: {
              label: "Date",
              placeholder: "Select period"
            }
          },
          productLabel: "Product",
          productDropdownOptions: {
            chooseAllLabel: "All"
            // add more options
          },
          adjustmentLabel: "Adjustment",
          locationLabel: "Location",
          locationDropdownOptions: {
            chooseAllLabel: "All"
            // add more options
          },

          userLabel: "User",
          userDropdownOptions: {
            chooseAllLabel: "All"
            // add more options
          },
          dateLabel: "Date",
          remarksLabel: "Remarks",
          remarksDropdownOptions: {
            chooseAllLabel: "All"
            // add more options
          }
        },

        confirmDelete: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete",
          deletionItem: "Stock transfer",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Transfer has been successfully deleted!",
            error: "An error occurred while deleting the transfer..."
          }
        },

        exporting: {
          dropdown: {
            label: "Heading",
            options: {
              productName: "Product name",
              fromLocation: "From location",
              toLocation: "To location",
              user: "User",
              remarks: "Remarks",
              date: "Date",

            }
          }
        }
      },

      stockTransfer: {
        disables: {
          fromLocation: {
            noProduct: "Select product first"
          },
          section: {
            noLocation: "Select location first",
            noSection: "Location doesn't have sections"
          },
          toLocation: {
            noFromLocation: "Select from location first",
            noFromSection: "Select from section first"
          }
        },
        fields: {
          product: {
            label: "Product",
            placeholder: "Select product"
          },
          fromLocation: {
            label: "From location",
            placeholder: "Select location"
          },
          section: {
            label: "Section",
            placeholder: "Select section"

          },
          toLocation: {
            label: "To location",
            placeholder: "Select location"
          },
          date: {
            label: "Date"
          },
          availableQuantity: {
            label: "Available quantity"
          },
          transferQuantity: {
            label: "Transfer quantity"
          },
          remarks: {
            label: "Remarks"
          }
        },
        saveButton: "Save",
        notFound: "Stock Transfer not found"
      },
      createTransfer: {
        heading: "Create new stock transfer",
        responses: {
          success: "Transfer has been successfully created!",
          error: "An error occurred while creating the transfer..."
        }
      },
      viewEditTransfer: {
        heading: "Stock transfer",
        responses: {
          success: "Transfer has been successfully updated!",
          error: "An error occurred while updating the transfer..."
        }
      },
      tooltip: "..."
    }
  },
  customers: {
    main: {
      table: {
        columns: ["Customer name / code", "Customer type", "Email", "Phone", "Contact name", "Action"],
        titleDeclinations: ["customer", "customers", "customers"],
        company: "Company",
        person: "Person"
      },
      modals: {
        export: {
          heading: "Export customers",
          dropdown: {
            label: "Heading",
            placeholder: "Select headers",
            options: {
              companyName: "Company name",
              email: "Email",
              phone: "Phone",
              country: "Country",
              vat: "VAT No.",
              contactPerson: "Contact person",
              address: "Address",
              group: "Group"
            }
          },
          buttons: {
            export: "Export"
          }
        }
      }
    },
    creation: {
      heading: "Create new customer",
      responses: {
        success: "Customer has been successfully created!",
        error: "An error occurred while creating a customer..."
      }
    },
    editing: {
      responses: {
        success: "Customer has been successfully updated!",
        error: "An error occurred while updating a customer..."
      }
    },
    viewPage: {
      sideButtons: {
        createSalesOrder: "Create sales order",
        edit: "Edit",
        delete: "Delete",
        sendEmail: "Send email"
      }
    },
    general: {
      save: "Save",
      alerts: {
        formIsInvalid: "Some fields are filled incorrectly!"
      },
      modals: {
        deletion: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete the",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Customer has been successfully deleted!",
            error: "Something went wrong while deleting the customer..."
          }
        }
      },
      tabs: {
        general: {
          heading: "General",
          categories: {
            contactInfo: "Contact information",
            additionalContactInfo: "Additional contact information"
          },
          fields: {
            customerName: {
              label: "Customer name"
            },
            customerCode: {
              label: "Customer code"
            },
            registrationNumber: {
              label: "Registration number"
            },
            contactName: {
              label: "Contact name"
            },
            phone: {
              label: "Phone"
            },
            email: {
              label: "Email"
            }
          },

          checkboxes: {
            isCompany: "Company",
            isPerson: "Person",
          },

          buttons: {
            addContact: "Add new contact",
            delete: "Delete",
            next: "Next"
          }
        },

        pricesAndDiscounts: {
          heading: "Prices & Discounts",
          table: {
            columns: ["Product name", "Price", "Discount (%)", "Min. quantity", "Valid from", "Valid till", "Action"],
            noProduct: "No products",
            buttons: {
              addProduct: "Add product"
            },
            expiredLabel: "Expired",
            noExpiration: "No expiration date",
          },
          modals: {
            addProduct: "Add product",
            editProduct: "Edit product",
            dropdowns: {
              product: {
                label: "Product",
                placeholder: "Select product"
              },
            },
            fields: {
              price: {
                label: "Price",
              },
              discount: {
                label: "Discount (%)",
                placeholder: "0.00"
              },
              minQuantity: {
                label: "Min.quantity",
              },
              validFrom: {
                label: "Valid from",
                placeholder: "Select date"
              },
              validTill: {
                label: "Valid till",
                placeholder: "Select date",
                tip: "(exclusive)"
              }
            },
            buttons: {
              add: "Add",
              save: "Save",
            }
          },
          buttons: {
            prev: "Back",
            next: "Next"
          }
        },

        address: {
          heading: "Address",
          categories: {
            billing: "Billing address",
            delivery: "Delivery address"
          },
          fields: {
            name: {
              label: "Name"
            },
            street: {
              label: "Street"
            },
            street2: {
              label: "Street 2"
            },
            zipcode: {
              label: "ZIP code",
            },
            city: {
              label: "City"
            },
            country: {
              label: "Country"
            },
            contact_person: {
              label: "Contact person"
            },
            phone: {
              label: "Phone"
            },
            email: {
              label: "Email"
            }
          },
          checkboxes: {
            useForShipping: "Use this address as shipping",
            useAsPrimary: "Use this address as primary delivery address"
          },
          buttons: {
            addDelivery: "Add new delivery address",
            delete: "Delete",
            prev: "Back",
            next: "Next" // TODO: change to Save and next in betas 1+
          }
        },
        settings: {
          heading: "Settings",
          fields: {
            discount: {
              label: "Discount(%)",
              placeholder: "Enter discount"
            },
          },
          dropdowns: {
            language: {
              label: "Language",
              placeholder: "Select language"
            },
            taxRate: {
              label: "Tax rate",
              placeholder: "Select tax rate"
            },
            paymentTerms: {
              label: "Payment terms",
              placeholder: "Select payment terms"
            },
            deliveryTerms: {
              label: "Delivery terms",
              placeholder: "Select delivery terms"
            },
            reference: {
              label: "Reference",
              placeholder: "Select reference"
            },
          },
          buttons: {
            prev: "Back",
            save: "Save"
          }
        },
        salesOrders: {
          heading: "Sales orders",
          columns: ["Sales order", "Invoiced", "Shipped", "Total", "Order date"],
          invoicedStatus: ["Not invoiced", "Invoiced"],
          shippedStatus: ["Not shipped", "Ready", "Delivered"],
        }
      }
    }
  },
  customerGroups: {
    main: {
      table: {
        columns: ["Group code", "Group name", "Discount (%)", "Action"],
      },
    },
    creation: {
      heading: "New customer group",
      responses: {
        success: "Customer group has been successfully created!",
        error: "An error occurred while creating a customer group..."
      }
    },
    editing: {
      responses: {
        success: "Customer group has been successfully updated!",
        error: "An error occurred while updating a customer group..."
      }
    },
    viewPage: {
      sideButtons: {
        edit: "Edit",
        delete: "Delete",
        sendEmail: "Send email"
      }
    },
    mutation: {
      fields: {
        groupCode: {
          label: "Group code",
          placeholder: "Enter code"
        },
        groupName: {
          label: "Group name",
          placeholder: "Enter name"
        },
        discount: {
          label: "Discount",
          placeholder: "Enter discount"
        },
      },
      dropdowns: {
        customer: {
          title: "Customers",
          label: "Customer",
          placeholder: "Select customer"
        },
      },
      table: {
        heading: "Products",
        noProduct: "No Products",
        columns: ["Product code", "Product name", "Unit price", "Valid from", "Valid till", "Action"],
        expiredLabel: "Expired",
        noExpiration: "No expiration date",
        buttons: {
          addProduct: "Add product",
        }
      },
      buttons: {
        save: "Save",
      },
      modals: {
        product: {
          addProduct: "Add product",
          editProduct: "Edit product",
          fields: {
            unitPrice: {
              label: "Unit price",
              placeholder: "Enter selling price"
            },
            validFrom: {
              label: "Valid from",
              placeholder: "Select date"
            },
            validTill: {
              label: "Valid till",
              placeholder: "Select date",
              tip: "(exclusive)"
            },
          },
          dropdowns: {
            product: {
              label: "Product",
              placeholder: "Select product"
            },
          },
          buttons: {
            add: "Add",
            save: "Save",
          },
        },
        deletion: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete the",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Customer group has been successfully deleted!",
            error: "An error occurred while deleting the customer group..."
          }
        }
      },
    },
  },
  suppliers: {
    main: {
      table: {
        columns: ["Supplier name / code", "Email", "Phone", "Contact name", "Action"],
        titleDeclinations: ["supplier", "suppliers", "suppliers"]
      },
      modals: {
        export: {
          heading: "Export suppliers",
          dropdown: {
            label: "Heading",
            placeholder: "Select headers",
            options: {
              companyName: "Supplier name",
              email: "Email",
              phone: "Phone",
              country: "Country",
              vat: "VAT No.",
              contactPerson: "Contact person",
              address: "Address",
            }
          },
          buttons: {
            export: "Export"
          }
        }
      }
    },
    creation: {
      heading: "Create new supplier",
      responses: {
        success: "Supplier has been successfully created!",
        error: "An error occurred while creating the supplier..."
      }
    },
    editing: {
      responses: {
        success: "Supplier has been successfully updated!",
        error: "An error occurred while updating the supplier..."
      }
    },
    viewPage: {
      sideButtons: {
        createPurchase: "Create purchase order",
        edit: "Edit",
        delete: "Delete",
        sendEmail: "Send email"
      }
    },

    general: {
      modals: {
        deletion: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete the",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            success: "Supplier has been successfully deleted!",
            error: "Something went wrong while deleting the supplier..."
          }
        }
      },

      tabs: {
        heading: "Create new supplier",
        create: "Create",
        save: "Save",
        alerts: {
          formIsInvalid: "Some fields are filled incorrectly!"
        },
        general: {
          heading: "General",
          categories: {
            contactInfo: "Contact information",
            additionalContactInfo: "Additional contact information"
          },
          fields: {
            supplierName: {
              label: "Supplier name"
            },
            supplierCode: {
              label: "Supplier code"
            },
            registrationNumber: {
              label: "Registration number"
            },
            contactName: {
              label: "Contact name"
            },
            phone: {
              label: "Phone"
            },
            email: {
              label: "Email"
            }
          },
          buttons: {
            delete: "Delete",
            next: "Next"
          }
        },

        address: {
          heading: "Address",
          categories: {
            billing: "Billing address",
            delivery: "Return address"
          },
          fields: {
            name: {
              label: "Name"
            },
            street: {
              label: "Street"
            },
            street2: {
              label: "Street 2"
            },
            zipcode: {
              label: "ZIP code",
            },
            city: {
              label: "City"
            },
            country: {
              label: "Country"
            },
            contact_person: {
              label: "Contact person"
            },
            phone: {
              label: "Phone"
            },
            email: {
              label: "Email"
            }
          },
          checkboxes: {
            useForReturn: "Use this address for return",
            useAsPrimary: "Use this address as primary return address"
          },
          buttons: {
            delete: "Delete",
            prev: "Back",
            next: "Next", // TODO: change to Save and next in betas 1+

          }
        },
        settings: {
          heading: "Settings",
          dropdowns: {
            currency: {
              label: "Currency",
              placeholder: "Select currency",
              base: "Base"
            },
            taxRate: {
              label: "Tax rate",
              placeholder: "Select tax rate"
            },
            paymentTerms: {
              label: "Payment terms",
              placeholder: "Select payment terms"
            },
            deliveryTerms: {
              label: "Delivery terms",
              placeholder: "Select delivery terms"
            },
            language: {
              label: "Language",
              placeholder: "Select language"
            },
          },
          buttons: {
            prev: "Back",
            save: "Save"
          }
        },
        purchaseOrders: {
          heading: "Purchase orders",
          columns: ["Purchase order", "Invoiced", "Received", "Total", "Order date"],
          invoicedStatus: ["Not invoiced", "In progress", "Invoiced"],
          receivedStatus: ["Not received", "In progress", "Received"],
          startReceiving: "Start receiving",
        },
        receives: {
          heading: "Receives",
          columns: ["Receive ID", "Purchase order", "Receive date"]
        }
      }
    }
  },
  settings: {
    sidebarLinks: { // TODO: [+nekear] added, but not implemented
      general: {
        title: "General"
      }
    },
    general: {
      companyInformation: {
        tabs: {
          companyDetails: {
            title: "Company details",
            logo: {
              label: "Logo",
              restriction: ["This logo will appear on transactions and email notifications.", "Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB."], // +[Nekear]
              prompt: "Drag image here" // +[Nekear]
            },
            fields: {
              companyName: "Company name",
              street: "Street",
              street2: "Street 2",
              city: "City",
              zipCode: "ZIP code",
              phone: "Phone",
              email: "Email",
              website: "Website",
              registrationNumber: "Registration number",
              printerEmail: {
                label: "Printer email",
                caption: "This email will be used to send delivery notes to the printer."
              },
            },
            dropdowns: {
              industry: {
                label: "Industry",
                placeholder: "Select"
              },
              country: {
                label: "Country",
                placeholder: "Select"
              },
              baseCurrency: {
                label: "Base currency",
                placeholder: "Select",
                caption: "This choice is final and cannot be modified once company is created."
              },
              language: {
                label: "Language",
                placeholder: "Select"
              },
              timezone: {
                label: "Timezone",
                placeholder: "Select"
              }
            },
            actions: {
              createNewCompanyButton: "Add new company",
              createNewCompanyButtonDropdown: "Create new", // +[Nekear]
              selectCompaniesDropdown: "Select company", // +[Nekear]
            },

            responses: {
              creation: {
                success: "Company has been created successfully!",
                error: "An error occurred while creating company!"
              },
              update: {
                success: "Company has been updated successfully!",
                error: "An error occurred while updating company!"
              },
              defaultUpdate: {
                error: "Unable to activate {{companyName}}!"
              },
              load: {
                error: "Unable to load {{companyName}}!"
              }
            },

            buttons: {
              save: "Save",
              confirm: "Confirm",
            }
          },
          address: {
            title: "Address",
            useThisAddressCheckbox: "Use this address for delivery",
            billingAddress: {
              heading: "Billing address", // +[Nekear]
              fields: {
                name: "Name",
                country: "Country",
                street: "Street",
                street2: "Street 2",
                zipCode: "Zip code",
                city: "City",
                email: "Email",
                phone: "Phone",
                contactName: "Contact name"
              }
            },
            deleteAddressButton: "Delete",
            modals: {
              deletion: {
                title: "Confirm deletion",
                hintText: "Are you sure you want to delete the address?",
                cancelButton: "Cancel",
                deleteButton: "Delete"
              }
            },
            deliveryAddress: {
              heading: "Delivery address", // +[Nekear]
              fields: {
                name: "Name",
                country: "Country",
                street: "Street",
                street2: "Street 2",
                zipCode: "Zip code",
                city: "City",
                email: "Email",
                phone: "Phone",
                contactName: "Contact name"
              },
              checkboxes: {
                useAsPrimary: "Use as primary delivery address"
              }
            },
            addNewDeliveryAddressButton: "Add New Delivery Address",
            responses: {
              update: {
                success: "Addresses have been updated successfully!",
                error: "An error occurred while updating company!"
              }
            },
            buttons: {
              previous: "Back", // +[Nekear]
              next: "Save", // +[Nekear]
              delete: "Delete"
            }
          },
          employees: {
            title: "Employees",
            table: {
              columns: ["ID Number", "Name", "Job title", "Email"],
              buttons: {
                add: "Add employee"
              }
            },
            modals: {
              mutation: {
                codeAlreadyExists: "Code is already taken!",
                creationHeading: "Create new employee",

                fields: {
                  code: {
                    label: "Code",
                  },
                  name:{
                    label: "Name",
                  },
                  jobTitle: {
                    label: "Job title",
                  },
                  email: {
                    label: "Email",
                  },
                  phone: {
                    label: "Phone",
                  },
                },

                dropdowns: {
                  language: {
                    label: "Language",
                    placeholder: "Select"
                  }
                },

                responses: {
                  creation: {
                    success: "Employee has been created successfully!",
                    duplicate: "Employee with this employee number already exists!",
                    error: "An error occurred while creating employee!"
                  },
                  update: {
                    success: "Employee has been updated successfully!",
                    error: "An error occurred while updating employee!"
                  }
                },

                buttons: {
                  save: "Save"
                }
              },
              deletion: {
                heading: "Confirm deletion",
                subheading: "Are you sure you want to delete the employee",
                buttons: {
                  cancel: "Cancel",
                  delete: "Delete"
                },
                responses: {
                  success: "Employee has been deleted!",
                  error: "An error occurred while deleting employee!"
                }
              }
            },
          },
          users: {
            title: "Users",
            heading: "Users",
            table: {
              columns: ["Full name", "Email", "Role", "Status", "Last login"],
              buttons: {
                invite: "Invite user"
              }
            },
            filter: {
              title: "Filter",
            },
            modals: {
              mutation: {
                creationHeading: "Invite user",
                fields: {
                  firstName: {
                    label: "First name",
                  },
                  lastName: {
                    label: "Last name",
                  },
                  email: {
                    label: "Email",
                  },
                  password: {
                    label: "Password",
                    placeholder: "Change the user password if necessary"
                  },
                },
                dropdowns: {
                  role: {
                    label: "Role",
                    placeholder: "Select role"
                  }
                },
                responses: {
                  passwordLength: "Password must be at least 8 characters long!",
                  invite: {
                    success: "User has been invited successfully!",
                    error: "An error occurred while inviting user!",
                    noPassword: "Password is required!"
                  },
                  update: {
                    success: "User has been updated successfully!",
                    error: "An error occurred while updating user!"
                  },
                },
                buttons: {
                  invite: "Invite",
                  save: "Save"
                },
              },
              deletion: {
                heading: "Confirm deletion",
                subheading: "Are you sure you want to delete the user",
                buttons: {
                  cancel: "Cancel",
                  delete: "Delete"
                },
                responses: {
                  success: "User has been deleted!",
                  error: "An error occurred while deleting user!"
                }
              },
              suspension: {
                heading: "Suspend user?",
                subheading: "Are you sure you want to suspend the user",
                buttons: {
                  cancel: "Cancel",
                  suspend: "Suspend"
                },
                responses: {
                  success: "User has been suspended!",
                  error: "An error occurred while suspending user!"
                }
              },
              activation: {
                heading: "Activate user",
                subheading: "Are you sure you want to activate the user",
                buttons: {
                  cancel: "Cancel",
                  activate: "Activate"
                },
                responses: {
                  success: "User has been activated!",
                  error: "An error occurred while activating user!"
                }
              }
            }
          },
          roles: {
            title: "Roles",
            heading: "Roles",
            table: {
              columns: ["Name", "Description"],
              buttons: {
                add: "Create role"
              }
            },
            creation: {
                heading: "Create new role",
                responses: {
                   success: "Role has been successfully created!",
                   error: "An error occurred while creating role!"
                }
            },
            editing: {
              responses: {
                success: "Role has been successfully updated!",
                error: "An error occurred while updating role!"
              }
            },
            mutation: {
              reloadWarning: {
                others: "UI Permissions will take effect after re-login. In some cases cache clearing is required using <badge>Ctrl + F5</badge>!",
              },
              title: {
                contacts: "Contacts",
                products: "Products",
                purchases: "Purchases",
                sales: "Sales",
                general: "General",
                dashboard: "Dashboard",
                integrations: "Integrations"
              },
              fields: {
                roleName: {
                  label: "Name",
                },
                roleDescription: {
                  label: "Description",
                  placeholder: "Enter role description"
                }
              },
              dropdowns: {
                supplier: {
                  label: "Supplier",
                  placeholder: "Select"
                },
                customer: {
                  label: "Customer",
                  placeholder: "Select"
                },
                customer_group: {
                  label: "Customer groups",
                  placeholder: "Select"
                },
                product: {
                  label: "Product",
                  placeholder: "Select"
                },
                bom: {
                  label: "Bill of materials",
                  placeholder: "Select"
                },
                adjustment: {
                  label: "Inventory adjustments",
                  placeholder: "Select"
                },
                collection: {
                  label: "Collections",
                  placeholder: "Select"
                },
                transfer: {
                  label: "Stock transfers",
                  placeholder: "Select"
                },
                stock_count: {
                    label: "Stock count",
                    placeholder: "Select"
                },
                location: {
                  label: "Locations",
                  placeholder: "Select"
                },
                purchase_order: {
                  label: "Purchase orders",
                  placeholder: "Select"
                },
                receive: {
                  label: "Receives",
                  placeholder: "Select"
                },
                sale_order: {
                  label: "Sales orders",
                  placeholder: "Select"
                },
                picking: {
                  label: "Pickings",
                  placeholder: "Select"
                },
                return: {
                  label: "Sale returns",
                  placeholder: "Select"
                },
                company: {
                  label: "Company",
                  placeholder: "Select"
                },
                address: {
                  label: "Address",
                  placeholder: "Select"
                },
                employee: {
                  label: "Employees",
                  placeholder: "Select"
                },
                default: {
                  label: "Defaults",
                  placeholder: "Select"
                },
                tax: {
                  label: "Tax rates",
                  placeholder: "Select"
                },
                package: {
                    label: "Packages",
                    placeholder: "Select"
                },
                category: {
                    label: "Categories",
                    placeholder: "Select"
                },
                currency: {
                  label: "Currencies",
                  placeholder: "Select"
                },
                unit: {
                  label: "Units",
                  placeholder: "Select"
                },
                payment_term: {
                  label: "Payment terms",
                  placeholder: "Select"
                },
                delivery_term: {
                  label: "Delivery terms",
                  placeholder: "Select"
                },
                language: {
                  label: "Languages",
                  placeholder: "Select"
                },
                profile: {
                  label: "Account profile settings",
                  placeholder: "Select"
                },
                user: {
                  label: "Users",
                  placeholder: "Select"
                },
                role: {
                  label: "Roles",
                  placeholder: "Select"
                },
                billing: {
                  label: "Billing",
                  placeholder: "Select"
                },
                subscription: {
                  label: "Subscriptions",
                  placeholder: "Select"
                },
                dashboard: {
                  label: "Dashboard",
                  placeholder: "Select"
                },
                integration: {
                  label: "Integration",
                  placeholder: "Select"
                },
                serial_number: {
                  label: "Serial numbers",
                  placeholder: "Select"
                },
                batch_number: {
                  label: "Batch numbers",
                  placeholder: "Select"
                },
                export: {
                    label: "Export",
                    placeholder: "Select"
                },
                custom_fields: {
                    label: "Custom fields",
                    placeholder: "Select"
                },
              },
              abilities: {
                  accessDenied: "Access denied",
                  index: "List view",
                  create: "Create",
                  update: "Update",
                  delete: "Delete",
                  show: "Record view",
                  export: "Export",
                  import: "Import",
                  invite: "Invite",
                  manage: "Manage",
                  currency: "Manage currency",
                  sales_activity: "Sales activity",
                  best_selling_products: "Best selling products",
                  revenue: "Revenue",
                  restocking: "Restocking",
                  feed: "Feed",
                  produce: "Produce",
                  production_history: "Production history",
                  start_import: "Start import",
                  update_selling_price: "Update selling price",
                  edit: "Edit",
                  run: "Run",
                  disable: "Disable"
              },
              buttons: {
                create: "Create role",
                save: "Save role"
              },
            },
            modals: {
              deletion: {
                heading: "Confirm deletion",
                subheading: "Are you sure you want to delete the role",
                buttons: {
                  cancel: "Cancel",
                  delete: "Delete"
                },
                responses: {
                  success: "Role has been deleted!",
                  error: "An error occurred while deleting role!"
                }
              },
            }
          },
          billing: {
            contactEmailDetails: {
              title: "Contact Email",
              accountEmail: "Send to my account email",
              alternativeEmail: {
                title: "Send to an alternative email",
                placeholder: "Enter alternative email"
              }
            },
            modals: {
              addNewPaymentDetails: {
                title: "Add new payment",
                fields: {
                  cardNumber: "Card number",
                  expirationDate: "Expiration date",
                  CVCCode: "CVC/CVV",
                  cardholderName: "Cardholder name"
                },
                addCard: "Add card"
              }
            },
            cardDetails: {
              title: "Card Details",
              addNewPayment: "Add New Payment"
            },
            paymentHistory: {
              title: "Payment History",
              columns: ["Invoice", "PO", "Amount", "Date", "Status", "Action"],
              status: {
                paid: "Paid"
              }
            }
          },
          notifications: {
            title: "Notifications",
            columns: ["Section", "Action", "Enable"],
            sections: {
              purchaseOrder: {
                title: "Purchase Order",
                actions: {
                  placed: "Placed",
                  completed: "Completed",
                  cancelled: "Cancelled"
                }
              },
              salesOrder: {
                title: "Sales Order",
                actions: {
                  placed: "Placed",
                  completed: "Completed",
                  cancelled: "Cancelled"
                }
              },
              shipment: {
                title: "Shipment",
                actions: {
                  shipped: "Shipped",
                  delivered: "Delivered"
                }
              },
              receive: {
                title: "Receive",
                actions: {
                  inProgress: "In progress",
                  received: "Received"
                }
              }
            }
          }
        },
        responses: {
          success: "Company information has been updated!",
          error: "Something went wrong. Please try again later.",

          activeChange: "Active company changed to",
        }
      },
      locations: {
        table: {
          columns: ["Location name", "Country", "City", "Sections", "Action"]
        },
        subtable: {
          rowHints: {
            name: "Section name",
            code: "Section code",
            sector: "Sector",
            row: "Row",
            shelfHeight: "Shelf height",
          }
        },

        createEditLocation: {
          // TODO: [-nekear] removed title from here
          location: {
            fields: {
              locationName: {
                label: "Location name"
              },
              country: {
                label: "Country",
                placeholder: "Select country"
              },
              city: {
                label: "City"
              },
              street: {
                label: "Street"
              },
              zipCode: {
                label: "ZIP code"
              },
              phoneNumber: {
                label: "Phone number"
              },
              contactName: {
                label: "Contact name"
              },
              email: {
                label: "Email"
              },
              options: {
                label: "Options",
                sectionsOption: "Sections"
              }
            }
          },

          sections: {
            title: "Sections",
            fields: {
              name: {
                label: "Section name"
              },
              sector: {
                label: "Sector"
              },
              row: {
                label: "Row" // TODO: [+nekear] added
              },
              shelfHeight: {
                label: "Shelf height"
              }
            },
            nothingFound: "No sections yet",
            button: "Add new section" // TODO: [+nekear] Added button
          }
          // TODO: [-nekear] removed buttons
        },

        createLocation: { // TODO: [+nekear] added specific translation blocks
          heading: "Create new location",
          button: "Create location",
          responses: {
            success: "Location has been successfully created!",
            error: "An error occurred while creating location!"
          }
        },

        editLocation: {
          button: "Save changes",
          responses: {
            success: "Location has been successfully updated!",
            error: "An error occurred while updating location!"
          }
        },

        modals: {
          filter: {
            fields: {
              country: {
                label: "Country",
                placeholder: "Select"
              },
              sections: {
                label: "Sections" // TODO: [-nekear] removed selectAll
              }
            }
          },

          // both for sections and locations
          confirmDelete: { // TODO: [+nekear] renamed
            label: "Confirm deletion",
            hint: "Are you sure you want to delete",
            sectionDeletionItem: "section",
            cancelButton: "Cancel",
            deleteButton: "Delete",
            responses: {
              location: {
                success: "Store deleted successfully!",
                error: "An error occurred while deleting the store!"
              }
            }
          }
        }
      },

      myAccount: {
        tabs: {
          profile: {
            title: "Profile",
            fields: {
              firstName: "First name",
              lastName: "Last name",
              email: "Email",
              phone: "Phone"
            },
            dropdowns: {
              country: {
                label: "Country",
                placeholder: "Select"
              },
              language: {
                label: "Language",
                placeholder: "Select"
              }
            },
            password: {
              title: "Password",
              fields: {
                currentPassword: "Current password",
                newPassword: "New password",
                confirmPassword: "Confirm password"
              }
            },
            responses: {
              noChanges: "No changes detected!",
              user: {
                success: "User has been successfully updated!",
                error: "An error occurred while updating user!"
              },
              password: {
                success: "Password has been successfully updated!",
                notMatchError: "Passwords do not match!",
                sameError: "New password cannot be the same as the old one!",
                incorrectError: "Incorrect current password!",
              },
            },
            save: "Save",
          },
          billing: {
            title: "Billing",
            contactEmailDetails: {
              title: "Contact Email",
              accountEmail: "Send to my account email",
              alternativeEmail: {
                title: "Send to an alternative email",
                placeholder: "Enter alternative email"
              }
            },
            cardDetails: {
              title: "Card Details",
              addNewPayment: "Add New Payment"
            },
            paymentHistory: {
              title: "Payment History",
              columns: ["Invoice", "PO", "Amount", "Date", "Status", "Action"],
              status: {
                paid: "Paid"
              }
            }
          },
          subscriptions: {
            title: "Subscriptions",
            startPackage: {
              title: "Start package",
              setupAccount: "Setup Suppli account",
              setupIntegrations: "Setup integrations",
              software: "Suppli software",
              coaching: "3 hours online employee training in use of Suppli",
              price: "Kr 15 000",
              buyButton: "Buy Now"
            },
            license: {
              title: "License",
              premium: {
                title: "Premium",
                price: "Kr 400",
                timing: "Month pr user"
              },
              warehouseAndScanning: {
                title: "Warehouse – and scanning of products",
                price: "Kr 200",
                timing: "Month pr user"
              },
              accounting: {
                title: "Accounting",
                price: "Kr 200",
                timing: "Month pr user"
              },
              businessPackage1: {
                title: "Business package 1",
                price: "Kr 2000",
                timing: "Month"
              },
              businessPackage2: {
                title: "Business package 2",
                price: "Kr 4500",
                timing: "Month"
              }
            },
            support: {
              title: "Support",
              bronze: {
                title: "Bronze",
                price: "Kr 999",
                timing: "Month"
              },
              silver: {
                title: "Silver",
                price: "Kr 1499",
                timing: "Month"
              },
              gold: {
                title: "Gold",
                price: "Kr 1499",
                timing: "Month"
              }
            },
            latestPayment: {
              title: "Latest payment",
              nextPayment: "Next payment",
              payNow: "Pay now",
              paymentDate: "Payment date",
              typeOfPlan: "Type of plan",
              paymentMethod: "Payment method",
              totalPayment: "Total payment"
            }
          },
        }
      },

      currencies: {
        heading: "Currencies",
        table: {
          columns: ["Name", "Symbol", "Exchange rate"],
          buttons: {
            add: "Add currency"
          },
          baseCurrency: "Base",
          tooltips: {
            unpin: "Unlock and Reset Exchange Rate",
            save: "Save and Lock Exchange Rate",
          },
        },

        responses: {
          currencyRate: {
            update: {
              error: "Unable to update exchange rate!"
            }
          },
        },

        modals: {
          mutation: {
            creationHeading: "Add new currency",

            fields: {
              symbol: {
                label: "Symbol"
              }
            },

            dropdowns: {
              type: {
                label: "Name",
                placeholder: "Select"
              }
            },

            responses: {
              creation: {
                success: "Currency added successfully",
                error: "Unable to add currency"
              },
            },

            buttons: {
              save: "Save"
            }
          },

          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deleted has been completed",

            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },

            responses: {
              success: "Currency has been deleted!",
              error: "An error occured while deleting the currency!"
            }
          }
        }
      },

      paymentTerms: {
        heading: "Payment terms",
        table: {
          columns: ["Name", "Days", "Type"],
          buttons: {
            add: "Add payment terms",
          }
        },

        modals: {
          mutation: {
            creationHeading: "Add new payment terms",

            fields: {
              name: {
                label: "Name",
              },
              days: {
                label: "Days",
              }
            },

            dropdowns: {
              type: {
                label: "Type",
                placeholder: "Select"
              }
            },

            responses: {
              creation: {
                success: "Payment terms created successfully!",
                error: "Something went wrong!"
              },
              update: {
                success: "Payment terms updated successfully!",
                error: "Something went wrong!"
              }
            },

            buttons: {
              save: "Save"
            }
          },
          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deleted has been completed",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Payment terms has been deleted!",
              error: "An error occurred while deleting the payment terms!"
            }
          }
        },

        paymentTypes: {
          daysAfter: "Days after",
          prepayment: "Prepayment",
        },
      },

      deliveryTerms: {
        heading: "Delivery terms",
        table: {
          columns: ["Name", "Description"],
          buttons: {
            add: "Add delivery terms",
          }
        },

        modals: {
          mutation: {
            creationHeading: "Add new delivery terms",

            fields: {
              name: {
                label: "Name",
              },
              description: {
                label: "Description",
              }
            },

            responses: {
              creation: {
                success: "Delivery terms created successfully!",
                error: "Something went wrong!"
              },
              update: {
                success: "Delivery terms updated successfully!",
                error: "Something went wrong!"
              }
            },

            buttons: {
              save: "Save"
            }
          },
          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deleted has been completed",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Delivery terms has been deleted!",
              error: "An error occurred while deleting the payment terms!"
            }
          }
        },
      },

      packaging: {
        heading: "Packaging",
        table: {
          columns: ['Name', "Code", "Width (mm)", "Length (mm)", "Height (mm)"],
          buttons: {
            add: "Add new shipping box",
          }
        },
        modals: {
          mutation: {
            creationHeading: "Add shipping box",

            fields: {
              name: {
                label: "Name",
              },
              code: {
                label: "Code",
              },
              width: {
                label: "Width (mm)",
              },
              length: {
                label: "Length (mm)",
              },
              height: {
                label: "Height (mm)",
              }
            },

            responses: {
              creation: {
                success: "Shipping box created successfully!",
                error: "Something went wrong!"
              },
              update: {
                success: "Shipping box updated successfully!",
                error: "Something went wrong!"
              },
              duplicateCode: "This code has already been taken."
            },

            buttons: {
              create: "Create",
              save: "Save"
            }
          },
          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deletion has been completed",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Shipping box has been deleted!",
              error: "An error occurred while deleting the shipping box!"
            }
          }
        }
      },

      units : {
        heading: "Units",
        table: {
          columns: ['Unit name', "Symbol", "Custom name"],
          buttons: {
            add: "Add new unit",
          }
        },
        modals : {
          mutation: {
            creationHeading: "Add unit",
            editHeading: "Edit unit",
            fields : {
              name: {
                label: "Unit"
              },
              symbol: {
                label: "Unit symbol"
              },
              customName : {
                label: "Custom unit name (optional)"
              }
            },
            responses: {
              creation: {
                success: "Unit created successfully! UwU",
                error: "Something went wrong!"
              },
              update: {
                success: "Unit updated successfully!",
                error: "Something went wrong!"
              },

            },

            buttons: {
              save: "Save"
            }
          },

          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deletion has been completed",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Unit has been deleted!",
              error: "An error occurred while deleting the unit!"
            }
          }
        }
      },
      languages: {
        heading: "Languages",
        table: {
          columns: ["Name", "Language code"],
          buttons: {
            add: "Add new language",
          }
        },
        modals: {
          mutation: {
            creationHeading: "Add new language",

            fields: {
              name: {
                label: "Name",
              },
              code: {
                label: "Language code",
              }
            },
            dropdowns: {
              language: {
                label: "Language *",
                placeholder: "Select"
              }
            },
            responses: {
              creation: {
                success: "Language created successfully!",
                duplicate: "This language is already selected for the company.",
                error: "Something went wrong!"
              },
              update: {
                success: "Language updated successfully!",
                error: "Something went wrong!"
              }
            },

            buttons: {
              save: "Save"
            }
          },
          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deleten has been completed.",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Language has been deleted!",
              error: "An error occurred while deleting the language!"
            }
          }
        }
      },
      taxRates: {
        heading: "Tax rates",
        table: {
          columns: ["Tax Name", "Tax rate (%)", "Sales tax", "Purchase tax"],
          buttons: {
            add: "Add tax rate",
          }
        },
        modals: {
          mutation: {
            creationHeading: "Add new tax rate",

            fields: {
              name: {
                label: "Name"
              },
              rate: {
                label: "Rate"
              },
              salesTax: {
                label: "Sales tax"
              },
              purchaseTax: {
                label: "Purchase tax"
              }
            },

            responses: {
              creation: {
                success: "Tax rate created successfully!",
                error: "Something went wrong!"
              },
              update: {
                success: "Tax rate updated successfully!",
                error: "Something went wrong!"
              }
            },

            buttons: {
              save: "Save"
            }
          },
          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deleted has been completed",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Tax rate has been deleted!",
              error: "An error occurred while deleting the tax rate!"
            }
          }
        }
      },
      categories: {
        heading: "Categories",
        table: {
          columns: ["Code", "Name", "Type"],
          isService: "Service",
          buttons: {
            add: "Add new category",
          }
        },

        modals: {
          mutation: {
            creationHeading: "Add new category",

            fields: {
              number: {
                label: "Code"
              },
              name: {
                label: "Name"
              },
              isService: {
                label: "Service"
              },
            },

            integrations: {
              poweroffice: {
                heading: "PowerOffice GO",
                salesAccount: {
                  label: "Sales account",
                  placeholder: "Select"
                },
              }
            },

            responses: {
              creation: {
                success: "Category created successfully!",
                error: "Something went wrong!"
              },
              update: {
                success: "Category updated successfully!",
                error: "Something went wrong!"
              }
            },

            buttons: {
              create: "Create",
              save: "Save"
            }
          },

          deletion: {
            heading: "Confirm deletion",
            subheading: "You can not change your mind once deletion has been completed",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              success: "Category has been deleted!",
              error: "An error occurred while deleting the category!"
            },
          }
        }
      },
      customFields: {
        main: {
          heading: "Custom fields",
          table: {
            disabled: "Disabled",
            buttons: {
              add: "Add new custom field"
            },
            nothingFound: "No custom fields have been added yet",
          }
        },
        modals: {
          mutation: {
            creationHeading: "New custom field",
            fields: {
              module: {
                label: "Module",
                placeholder: "Select module"
              },
              label: {
                label: "Label",
                placeholder: "Enter label"
              },
              placeholder: {
                label: "Placeholder",
                placeholder: "Enter placeholder"
              }
            },
            buttons: {
              save: "Save"
            },
            responses: {
              creation: {
                success: "Custom field has been created!",
              },
              update: {
                success: "Custom field has been updated!"
              }
            }
          },
          toggling: {
            enable: {
                heading: "Enable custom field",
                subheading: `Are you sure you want to enable "<b>{{field}}</b>"?`,
                buttons: {
                  cancel: "Cancel",
                  action: "Enable"
                },
                responses: {
                  success: "Custom field has been enabled!",
                }
            },
            disable: {
                heading: "Disable custom field",
                subheading: `Are you sure you want to disable "<b>{{field}}</b>"?`,
                tip: "Disabled fields will be retained on old records but won't be visible on new ones.",
                buttons: {
                  cancel: "Cancel",
                  action: "Disable"
                },
                responses: {
                  success: "Custom field has been disabled!",
                }
            },
          },
          deletion: {
            heading: "Delete custom field",
            subheading: `Are you sure you want to delete"<b>{{field}}</b>"?`,
            tip: "Deleted fields will be removed from all records.",
            buttons: {
              cancel: "Cancel",
              action: "Delete"
            },
            responses: {
              success: "Custom field has been deleted!",
            }
          }
        },
        modules: {
          product: "Products"
        },
        inject: {
          title: "Custom fields",
          activateCustomFields: "Activate custom fields",
        }
      }
    },
    integrations: {
      thirdParty: {
        heading: "Integrations",
        badge: "Integrated: {{count}} of {{limit}}",
        modals: {
          creation: {
            creationHeading: "Connect your {{type}} account",

            responses: {
              success: "Integration set up successfully!",
              error: "Couldn't connect the integration!"
            },

            buttons: {
              save: "Connect"
            }
          },

          mutation: {
            mutationHeading: "Edit integration",

            responses: {
              creation: {
                success: "Integration set up successfully!",
                error: "Something went wrong."
              },
              update: {
                success: "Integration has been updated!",
                error: "Something went wrong!"
              }
            },

            buttons: {
              save: "Update"
            }
          },

          disable: {
            heading: "Disable your {{slug}} account",
            subheading: "Are you sure you want to disable your <strong>{{slug}}</strong> account?",
            warning: "This will remove all the data from your account",
            buttons: {
              cancel: "Cancel",
              disable: "Disable"
            },
            responses: {
              success: "Integration has been disabled!",
              error: "An error occurred while disabling the integration!"
            }
          }

        },

        integrationTypes: {
          all: "All",
          accounting: "Accounting",
          shipping: "Shipping",
          integratedApps: "Integrated Apps"
        },
        buttons: {
          integrationsLimitReached: "You have reached the limit of connected integrations. Please disable one of the existing integrations to connect a new one.",
          integrate: "Integrate",
          import: "Import",
          disable: "Disable",
        }
      }
    }
  },

  purchaseOrder: { // TODO: [+NEKEAR] added purchase section
    main: {
      table: {
        columns: ["Order code", "Reference", "Supplier", "Date", "Receive", "Total", "Docs", "Action"],
      },
      subtable: {
        columns: ["Product code", "Product", "Quantity", "Unit price", "Discount", "Tax", "Total"]
      },
      actions: {
        receive: "Receive",
        details: "View details",
        duplicate: "Duplicate",
        edit: "Edit",
        delete: "Delete",
      },
      filters: {
        supplier: {
          label: "Supplier",
        },
        product: {
          label: "Product",
        },
        receive: {
          label: "Receive",
          options: {
            received: "Received",
            inProgress: "In progress",
            notReceived: "Not received",
          }
        },
        deliveryDate: {
          label: "Delivery date",
          placeholder: "Select period"
        }
      }
    },
    language: {
      header: "Language",
      label: "Select document language",
      placeholder: "Select language"
    },
    general: {
      categories: {
        references: "References",
        address: "Address",
        shipping: "Shipping cost",
      },
      fields: {
        preferredDeliveryDate: {
          label: "Preferred delivery date",
          placeholder: "Select date"
        },
        purchaseDate: {
          label: "Purchase date",
          placeholder: "Select date"
        },
        shippingCost: {
          label: "Shipping cost",
          checkboxes: {
            splitByProducts: "Split by products"
          }
        },
        billingAddress: {
          label: "Billing address",
          placeholderWhenUndefined: "No billing address added",
          buttons: {
            create: "Create"
          },
          checkboxes: {
            useForDelivery: "Use this address for delivery"
          },
          modalCreation: {
            heading: "Create billing address",
            fields: {
              name: "Name",
              street: "Street",
              street2: "Street 2",
              zipCode: "ZIP code",
              city: "City",
              email: "Email",
              phone: "Phone",
              contactName: 'Contact name'
            },
            dropdowns: {
              country: {
                label: "Country",
                placeholder: "Select country"
              }
            },
            buttons: {
              save: "Save"
            },
            responses: {
                success: "Billing address has been created successfully!",
                error: "An error occurred while creating the billing address..."
            }
          }
        },
        exchangeRate: {
            label: "Exchange rate",
            placeholder: "Enter exchange rate",
            lockWarning: "Rate is fixed and does not mirror current real-world exchange rates."
        }
      },
      accentFields: {
        status: {
          label: "Status",
          variants: {
            notReceived: "Not received",
            inProgress: "In progress",
            received: "Received",
          }
        },

        orderDate: {
          label: "Order date",
        },

        receivedAt: {
          label: "Received at",
          variants: {
            notReceived: "Not received",
            partiallyReceived: "Partially received",
            received: "Received",
          }
        }
      },
      dropdowns: {
        supplier: {
          label: "Supplier",
          placeholder: "Select supplier",
          buttons: {
            create: "Create"
          }
        },
        ourReference: {
          label: "Our reference",
          placeholder: "Select reference",
          buttons: {
            create: "Create"
          },
        },
        theirReference: {
          label: "Their reference",
          placeholder: "Select reference",
          buttons: {
            create: "Create"
          },
          blockedBeforeSupplier: "Select supplier first"
        },
        paymentTerms: {
          label: "Payment terms",
          placeholder: "Select payment terms"
        },
        deliveryTerms: {
          label: "Delivery terms",
          placeholder: "Select delivery terms"
        },
        documentLanguage: {
          label: "Document language",
          placeholder: "Select language"
        },
        currency: {
          label: "Currency",
          placeholder: "Select currency",
          base: "Base"
        },
        deliveryMethod: {
          label: "Delivery method",
          placeholder: "Select delivery method"
        },
        deliveryAddress: {
          label: "Delivery address",
          placeholder: "Select address",
          buttons: {
            create: "Create"
          },
          creationModal: {
            heading: "Create delivery address",
            fields: {
              name: "Name",
              street: "Street",
              street2: "Street 2",
              zipCode: "ZIP code",
              city: "City",
              email: "Email",
              contactName: 'Contact name',
              phone: "Phone"
            },
            dropdowns: {
              country: {
                label: "Country",
                placeholder: "Select country"
              }
            },
            buttons: {
              save: "Save"
            },
            responses: {
                success: "Delivery address has been created!",
                error: "Something went wrong!"
            }
          }
        },
      },
      orders: {
        heading: "Products",
        columns: ["Product code", "Product", "Qty", "Unit price", "Discount", "Tax", "Total", "Action"],
        tip: {
          minPurchaseQTY: "Selected quantity is less than minimum purchase quantity",
        },
        buttons: {
          addProduct: "Add product",
          addCollection: "Add collection",
        },
        summary: {
          subtotal: "Subtotal",
          taxes: "Tax",
          shipping: "Shipping cost",
          total: "Total",
        },
        noProducts: "No products",
        modals: {
          createProduct: {
            heading: "Create new product",
            responses: {
              success: "Product has been created successfully!",
              duplicate: "This product code has already been taken.",
              error: "An error occurred while creating the product..."
            },
            fields: {
              productName: "Product name",
              productCode: "Product code",
              purchasePrice: "Purchase price",
              sellingPrice: "Selling price",
            },
            dropdowns: {
              category: {
                label: "Category",
                placeholder: "Select category"
              },
              unit: {
                label: "Unit",
                placeholder: "Select unit"
              },
              currency: {
                label: "Currency",
                placeholder: "Select currency"
              },
            },
            buttons: {
              create: "Create",
            },
          },
          addCollection: {
            heading: "Add collection",
            fields: {
              collection: {
                label:"Collection",
                placeholder: "Select"
              },
              quantity: {
                label: "Quantity",
                placeholder: "Enter quantity"
              },

            },
            buttons: {
              add: "Add"
            }
          }
        },
      },
      buttons: {
        create: "Create",
        save: "Save",
        sendToSupplier: "Send to supplier",
      },
      toasts: {
        noProductsAdded: "You have to add at least one product!",
      },
      modals: {
        createSupplier: {
          heading: "Create new supplier",
          categories: {
            address: "Address",
          },
          fields: {
            supplierName: "Supplier name",
            supplierCode: "Supplier code",
            name: "Name",
            street: "Street",
            street2: "Street 2",
            zipcode: "Zip code",
            city: "City",
            email: "Email",
            phone: "Phone",
          },
          dropdowns: {
            country: {
              label: "Country",
              placeholder: "Select"
            },
          },
          responses: {
            success: "Supplier has been created successfully!",
            error: "An error occurred while creating the supplier..."
          },
          buttons: {
            create: "Create"
          }
        },
        createOurReference: {
          heading: "Create new our reference",
          fields: {
            name: "Name",
            code: "Code",
            jobTitle: "Job title",
            phone: "Phone",
            email: "Email",
          },
          dropdowns: {
            language: {
              label: "Language",
              placeholder: "Select language"
            }
          },
          buttons: {
            create: "Create"
          },
          responses: {
            success: "Our reference has been created successfully!",
            error: "An error occurred while creating the our reference..."
          }
        },
        createTheirReference: {
          heading: "Create new their reference",
          fields: {
            name: "Name",
            phone: "Phone",
            email: "Email",
          },
          buttons: {
            create: "Create"
          },
          responses: {
            success: "Their reference has been created successfully!",
            error: "An error occurred while creating the their reference..."
          }
        },
        receive: {
          heading: "Receive purchase",
          subheading: [
            "Please select",
            "Partial",
            "(allows you to select which items to be received)",
            "or",
            "Quick receive",
            "(automatically receipts all items)"
          ],
          buttons: {
            partial: "Partial receive",
            quick: "Quick receive",
          },
          responses: {
            error: "An error ocurred while trying to quick receive!",
            success: "Purchase order has been received!"
          }
        },
        deletion: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete",
          purchaseOrderDeletionItem: "Purchase order",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            error: "An error occurred while trying to delete purchase order!",
            success: "Purchase order has been deleted!"
          }
        },
        addProduct: {
          heading: "Add product",
          fields: {
            product: {
              label: "Product",
              placeholder: "Select product"
            },
            quantity: {
              label: "Quantity",
              placeholder: "Enter quantity"
            },
            unitPrice: {
              label: "Unit price",
              placeholder: "Enter unit price"
            },
            discount: {
              label: "Discount",
              placeholder: "Enter discount"
            },
          },
          bom: {
            label: "Select as Bill Of Materials",
            whatIsBOM: "Bill of Materials (BOM) is a list of the components and the quantities of each needed to manufacture an end product. You can configure them in the corresponding module.",
            table: {
              columns: ["Name", "Total price"],
              subtable: {
                columns: ["Component name", "Quantity", "Purchase price", "Total price", "In stock"]
              },
              actions: {
                select: {
                  idle: "Select",
                  selected: "Selected"
                }
              }
            },
            validation: {
              noBOMSelected: "Select at least one bill of materials!"
            }
          },
          buttons: {
            add: "Add"
          }
        }
      }
    },
    mutations: {
      creation: {
        heading: "New purchase order",
        responses: {
          success: "Purchase order created successfully!",
          error: "Purchase order creation failed!"
        }
      },
      editing: {
        heading: "Purchase",
        responses: {
          success: "Purchase order updated successfully!",
          error: "Purchase order update failed!"
        }
      },

      details: {
        heading: "Purchase order",
        receiptsSection: {
            heading: "Receipts",
            noReceiptsFoundTooltip: "This order hasn’t been received yet",
            columns: ["Receipt code", "Status", "Date", "Worker"],
            receiveStatus: ["Partially received", "Completed"]
        }
      }
    }
  },

  receive: {
    main: {
      table: {
        columns: ["Receive code", "Purchase order", "Supplier", "Date", "Action"],
      },
      subtable: {
        columns: ["Product code", "Product", "Location", "Section", "Quantity"],
      },
      filters: {
        supplier: {
          label: "Supplier",
        },
        date: {
          label: "Date",
          placeholder: "Select period"
        }
      }
    },
    general: {
      of_part: "of", // used in quantity 1 *of* 10
      modals: {
        deletion: {
          heading: "Confirm deletion",
          subheading: "Are you sure you want to delete",
          buttons: {
            cancel: "Cancel",
            delete: "Delete"
          },
          responses: {
            error: "An error occurred while trying to delete the receive!",
            success: "Receipt has been deleted!"
          }
        },
      }
    },
    mutation: {
      headings: {
        creation: "New receiving",
      },
      fields: {
        supplier: {
          label: "Supplier",
          placeholder: "Select supplier"
        },
        purchaseOrder: {
          label: "Purchase order",
          placeholder: "Select purchase order",
          receivedBadge: "Received"
        },
        receiveDate: {
          label: "Receive date",
        }
      },
      products: {
        heading: "Products",
        columns: ["Product code", "Product", "Location", "Quantity", "Action"],
        noProducts: "No products yet",
        alreadyReceived: "This purchase order has been already received",
        buttons: {
          addSerialNumbers: "Add serial numbers",
          addBatchNumbers: "Add batch numbers",
        },

        errors: {
          fillAllSerialNumbers: "Please fill in all serial numbers!",
          fillAllBatchNumbers: "Please fill in all batch numbers!",
        }
      },
      alerts: {
        incompleteSerialNumbers: "Incomplete Serial Numbers. Please fill in all serial numbers.",
        incompleteBatchNumbers: "Incomplete Batch Numbers. Please fill in all batch numbers."
      },
      buttons: {
        save: "Save"
      },
      responses: {
        creation: {
          success: "Receipt created successfully!",
          error: "Something went wrong..."
        }
      }
    },
    modals: {
      serialNumbers: {
        field: {
          placeholder: "Serial number"
        },
        buttons: {
          attach: "Attach",
          cancel: "Cancel",
          addSerialNumber: "Add serial number",
        },
        noProductsPicked: "No products with serial numbers picked",
      },
      batchNumbers: {
        fields: {
          batchNumber: {
            placeholder: "Batch number"
          },
          expirationDate: {
            placeholder: "Expiration date"
          }
        },
        buttons: {
          attach: "Attach",
          cancel: "Cancel",
          addBatchNumber: "Add batch number",
        },
        noProductsPicked: "No products with batch numbers picked",
      }
    },
    details: {
      heading: "Receive",
      fields: {
        supplier: "Supplier",
        purchaseOrder: "Purchase order",
        receiveDate: "Receive date",
      },
      products: {
        heading: "Products",
        columns: ["Product code", "Product", "Location", "Quantity", "Action"],
        noProducts: "No products yet",
        modalReferences: {
          serialNumbers: "Serial numbers",
          batchNumbers: "Batch numbers",
        }
      },
    }
  },

  sales: {
    orders: { // TODO: [+NEKEAR] added sales orders section
      main: {
        table: {
          columns: ["Order code", "Customer", "Shipped", "Invoiced", "Date", "Total", "Docs", "Action"],
        },
        subtable: {
          columns: ["Product code", "Product", "Quantity", "Unit price", "Discount", "Tax", "Total"]
        },
        warnings: {
          createPOFailed: "Unable to obtain stock values for order lines, defaulting ordered quantity.",
          createPOAllInStock: "All products are in stock, no purchase order will be created.",
        },
        actions: {
          addToPurchaseOrder: "Add to Purchase Order",
          details: "View details",
          duplicate: "Duplicate",
          edit: "Edit",
          delete: "Delete",
          createPO: "Create purchase order",
          picking: "Create picking"
        },
        filters: {
          customer: {
            label: "Customer",
          },
          product: {
            label: "Product",
          },
          date: {
            label: "Date",
            placeholder: "Select date"
          },
          shipped: {
            label: "Shipped",
            options: {
              shipped: "Shipped",
              ready: "Ready",
              notShipped: "Not shipped",
            }
          },
          invoiced: {
            label: "Invoiced",
            options: {
              invoiced: "Invoiced",
              notInvoiced: "Not invoiced",
            }
          },
        }
      },
      general: {
        categories: {
          references: "References",
          address: "Address",
        },
        fields: {
          preferredDeliveryDate: {
            label: "Preferred delivery date",
            placeholder: "Select date"
          },
          orderDate: {
            label: "Order date",
            placeholder: "Select date"
          },
          billingAddress: {
            label: "Billing address",
            blockedBeforeCustomers: "Select customer first",
            placeholderWhenUndefined: "No billing address added",
            checkboxes: {
              useForDelivery: "Use this address for delivery"
            },
            buttons: {
              create: "Create"
            },
            creationModal: {
              heading: "Create billing address",
              responses: {
                success: "Billing address created successfully!",
                error: "Something went wrong!"
              },
              fields: {
                name: "Name",
                street: "Street",
                street2: "Street 2",
                zipcode: "Zip code",
                city: "City",
                email: "Email",
                phone: "Phone",
              },
              dropdowns: {
                country: {
                  label: "Country",
                  placeholder: "Select country"
                }
              },
              buttons: {
                create: "Create"
              },
            },
          },
        },
        accentFields: {
          deliveryStatus: {
            label: "Status",
            variants: {
              not_shipped: "Not delivered",
              partially_delivered: "Partially delivered",
              delivered: "Delivered",
            }
          },
          orderDate: {
            label: "Order date",
          },
          deliveryDate: {
            label: "Delivery date",
          }
        },

        dropdowns: {
          customer: {
            label: "Customer",
            placeholder: "Select customer",
            buttons: {
                create: "Create"
            },
            creationModal: {
              heading: "Create new customer",
              responses: {
                success: "Customer has been created successfully!",
                error: "An error occurred while creating the customer..."
              },
              subheading: "Billing address",
              fields: {
                customerName: "Customer name",
                customerCode: "Customer code",
                name: "Name",
                street: "Street",
                street2: "Street 2",
                zipcode: "Zip code",
                city: "City",
                email: "Email",
                phone: "Phone",
              },
              dropdowns: {
                country: {
                  label: "Country",
                  placeholder: "Select country",
                },
              },
              checkboxes: {
                isCompany: "Company",
                isPerson: "Person",
              },
              buttons: {
                create: "Create"
              }
            },
          },
          ourReference: {
            label: "Our reference",
            placeholder: "Select reference",
            buttons: {
              create: "Create"
            },
            creationModal: {
              heading: "Create new our reference",
              fields: {
                name: "Name",
                code: "Code",
                jobTitle: "Job title",
                phone: "Phone",
                email: "Email",
              },
              dropdowns: {
                language: {
                  label: "Language",
                  placeholder: "Select language"
                }
              },
              buttons: {
                create: "Create"
              },
              responses: {
                success: "Our reference has been created successfully!",
                error: "An error occurred while creating the our reference..."
              }
            },
          },
          theirReference: {
            label: "Their reference",
            placeholder: "Select reference",
            blockedBeforeCustomers: "Select customer first",
            buttons: {
              create: "Create"
            },
            creationModal: {
              heading: "Create new their reference",
              fields: {
                name: "Name",
                phone: "Phone",
                email: "Email",
              },
              buttons: {
                create: "Create"
              },
              responses: {
                success: "Their reference has been created successfully!",
                error: "An error occurred while creating the their reference..."
              }
            },
          },
          paymentTerms: {
            label: "Payment terms",
            placeholder: "Select payment terms"
          },
          deliveryTerms: {
            label: "Delivery terms",
            placeholder: "Select delivery terms"
          },
          documentLanguage: {
            label: "Document language",
            placeholder: "Select language"
          },
          currency: {
            label: "Currency",
            placeholder: "Select currency"
          },
          deliveryMethod: {
            label: "Delivery method",
            placeholder: "Select delivery method"
          },
          deliveryAddress: {
            label: "Delivery address",
            placeholder: "Select address",
            blockedBeforeCustomers: "Select customer first",
            creationModal: {
              heading: "Create delivery address",
              fields: {
                name: "Name",
                street: "Street",
                street2: "Street 2",
                zipcode: "Zip code",
                city: "City",
                email: "Email",
                contactName: "Contact Name",
                phone: "Phone",
              },
              dropdowns: {
                country: {
                  label: "Country",
                  placeholder: "Select country"
                }
              },
              buttons: {
                create: "Create"
              },
              responses: {
                success: "Delivery address created successfully!",
                error: "Something went wrong!"
              }
            },
          },
        },
        orders: {
          heading: "Products",
          lowStockWarning: "Stock levels are too low for certain products. A purchase order suggestion will be offered to complete the order.",
          tip: {
            minSaleQTY: "Selected quantity is less than minimum sale quantity",
          },
          columns: ["Product code", "Product", "Qty", "Unit price", "Discount", "Tax", "Total", "Action"],
          buttons: {
            addProduct: "Add product",
            addCollection: "Add collection",
          },
          quantityCell: {
            lowStockWarning: "{{inStock}} in stock"
          },
          summary: {
            subtotal: "Subtotal",
            taxes: "Tax",
            total: "Total",
          },
          noProducts: "No products",

          modals: {
            addProduct: {
              heading: "Add product",
              cards: {
                titles: {
                  pricesAndDiscounts: "Prices & Discounts",
                  generalCustomerDiscount: "General customer discount",
                  customerGroup: "Customer groups",
                },
                fields: {
                  discount: {
                    label: "Discount",
                  },
                  salePrice: {
                    label: "Unit price",
                  },
                  calculatedPrice: {
                    label: "Calculated price",
                  },
                  validFrom: {
                    label: "Valid from",
                  },
                  validTo: {
                    label: "Valid to",
                    noExpiration: "No expiration date",
                  },
                }
              },
              fields: {
                product: {
                    label: "Product",
                    placeholder: "Select"
                },
                quantity: {
                    label: "Quantity",
                    placeholder: "Enter quantity"
                },
                unitPrice: {
                    label: "Unit price",
                    placeholder: "Enter unit price"
                },
                discount: {
                    label: "Discount",
                    placeholder: "Enter discount"
                },
              },
              responses: {
                errorExtendedLoad: "Unable to load additional product information!",
              },
              buttons: {
                add: "Add"
              },
            },
            addCollection: {
              heading: "Add collection",
              fields: {
                collection: {
                  label: "Collection",
                  placeholder: "Select",
                },
                quantity: {
                  label: "Quantity",
                  placeholder: "Enter quantity",
                },
              },
              buttons: {
                add: "Add",
              },
            },
          }
        },
        pickingsSection: {
          heading: "Pickings",
          noPickingsFoundTooltip: "This order hasn’t been picked yet",
          columns: ["Picking code", "Status", "Invoicing", "Delivery status", "Date", "Worker"],
          pickingStatus: ["Partially picked", "Picked"],
          invoicing: {
            status: ["Not invoiced", "Invoiced"],
          },
          delivery: {
            status: {
              "not_shipped": "Not shipped",
              "in_progress": "In progress",
              "delivered": "Delivered",
            }
          },
        },
        buttons: {
          create: "Create",
          save: "Save",
          sendToSupplier: "Send to customer",
        },
        toasts: {
          noProductsAdded: "You have to add at least one product!",
        },
        modals: {
          deletion: {
            heading: "Confirm deletion",
            subheading: "Are you sure you want to delete",
            orderDeletionItem: "Order",
            buttons: {
              cancel: "Cancel",
              delete: "Delete"
            },
            responses: {
              error: "An error occurred while trying to delete sale order!",
              success: "Sale order has been deleted!"
            }
          }
        },
      },
      language: {
        header: "Language",
        label: "Select document language",
        placeholder: "Select language"
      },
      mutations: {
        creation: {
          heading: "New sale order",
          responses: {
            success: "Sale order created successfully!",
            error: "Sale order creation failed!"
          },

          lowStockOffer: {
            heading: "Would you like to create a purchase order?",
            subheading: "Some of your products are too low in stock to fulfill the sale order. <br/> Would you like to create a purchase order to complete the sale order?",
            columns: ["Product code", "Product name", "Ordered quantity", "Quantity in stock"],
            buttons: {
              create: "Create",
              cancel: "Cancel"
            },
            modals: {
              refill: {
                heading: "Configure low stock refill",
                smartRefill: {
                  label: "Use smart refill of components",
                  tooltip: "Automatically calculate the required number of components based on current stock levels."
                },
                selector: {
                  keywords: ["Refill", "as"],
                  refillTypeOptions: {
                    product: "Product",
                    bom: "BOM"
                  }
                },
                bom: {
                  table: {
                    columns: ["Name", "Price per one", "Total price"],
                    subtable: {
                      columns: ["Component name", "Quantity", "Purchase price", "Total price", "In stock"]
                    },
                    actions: {
                      select: {
                        idle: "Select",
                        selected: "Selected"
                      }
                    },
                  }
                },
                buttons: {
                  cancel: "Cancel",
                  refill: "Purchase"
                },
                validation: {
                    noBOMSelected: "Select one bill of materials!"
                },
                responses: {
                  unableToLoadProductsData: "Unable to load products data!",
                }
              }
            }
          }
        },
        editing: {
          heading: "Sale order",
          responses: {
            success: "Sale order updated successfully!",
            error: "Sale order update failed!"
          }
        },
        details: {
          heading: "Sale order",
        }
      }
    },
    picking: {
      main: {
          table: {
              columns: ["Picking code", "Sale order code", "Customer", "Invoiced", "Delivery status", "Date", "Action"],
          },
          subtable: {
              columns: ["Product code", "Product", "Quantity ordered", "Quantity picked"],
          },
          invoicing: {
            status: ["Not invoiced", "Invoiced"],
          },
          delivery: {
            status: {
              "not_shipped": ["Not shipped", "Products packed"],
              "in_progress": "In progress",
              "delivered": "Delivered",
            }
          },
          actions: {
              startPicking: "Start picking",
              continuePicking: "Continue picking",
              viewDetails: "View details",
          },
          filters: {
              customer: {
                  label: "Customer",
                  placeholder: "Select customer"
              },
              date: {
                  label: "Date",
                  placeholder: "Select period"
              },
              invoicing: {
                  label: "Invoicing status",
              }
          },
          status: ["New", "Partially picked", "Picked"],
          responses: {
            deliveryNoteFetchError: "Unable to download a delivery note!",
          }
      },
      mutation: {
          heading: "New picking",
          responses: {
                success: "Picking list updated successfully!",
                error: "Something went wrong..."
          }
      },
      modals: {
        packaging: {
          heading: "Packaging",
          dropdowns: {
            shippingBox: {
              label: "Shipping box",
              placeholder: "Select shipping box"
            }
          },
          fields: {
            quantity: "Quantity"
          },
          buttons: {
            save: "Save"
          }
        },
        serialNumbers: {
          field: {
            placeholder: "Serial number"
          },
          buttons: {
            attach: "Attach",
            cancel: "Cancel",
            addSerialNumber: "Add serial number",
          },
          noProductsPicked: "No products with serial numbers picked",
        },
        batchNumbers: {
          fields: {
            batchNumber: {
              placeholder: "Batch number"
            },
            expirationDate: {
              placeholder: "Expiration date"
            }
          },
          buttons: {
            attach: "Attach",
            cancel: "Cancel",
            addBatchNumber: "Add batch number",
          },
          noProductsPicked: "No products with batch numbers picked",
        }
      },
      details: {
          heading: "Picking list"
      },
      general: {
        fields: {
          customer: {
            label: "Customer",
            placeholder: "Select customer"
          },
          saleOrder: {
            label: "Sale order",
            placeholder: "Select order"
          },
          invoicing: {
            label: "Invoicing status",
          },
          delivery: {
            label: "Delivery status",
          },
          date: {
            label: "Date",
          },
          pickingDate: {
            label: "Picking date",
            placeholder: "Select date"
          },
          packaging: {
            label: "Packaging",
            placeholder: "Select shipping box",
            validation: {
              shipmondoRequirement: "At least 1 package is required for Shipmondo"
            },
            notSelected: "No packaging selected"
          }
        },
        invoicing: {
          status: ["Not invoiced", "Invoiced"],
        },
        delivery: {
          status: {
            "not_shipped": ["Not shipped", "Products packed"],
            "in_progress": "In progress",
            "delivered": "Delivered",
          }
        },
        products: {
          heading: "Products",
          columns: ["Product code", "Product", "Quantity ordered", "Outgoing location", "Quantity", "Action", "Quantity picked", "Status"],
          noProducts: "No products yet",
          quantityLeftPostfix: "left",
          quantityPickedPostfix: "picked",
          summary: {
            ordered: "Total quantity ordered",
            picked: "Total quantity picked",
          },

          pickStatus: ["New", "Partially", "Completed"],

          buttons: {
            addSerialNumbers: "Add serial numbers",
            addBatchNumbers: "Add batch numbers",
          },

          modalReferences: {
            serialNumbers: "Serial numbers",
            batchNumbers: "Batch numbers",
          },

          errors: {
            fillAllSerialNumbers: "Please fill in all serial numbers!",
            fillAllBatchNumbers: "Please fill in all batch numbers!",
          }
        },
        responses: {
          unableToLoadExtendedSO: "Unable to load sale order information!",
        },
        buttons: {
          addOutgoingLocation: "Add location",
          save: "Save"
        }
      },
      shipmondo: {
        heading: "Shipmondo",
        integrationBadge: "Integration",
        shipmondoNotUsed: "Shipmondo is not used for this order",
        fields: {
            country: {
                label: "Outgoing country",
                placeholder: "Select country"
            },
            destinationCountry: {
              label: "Destination country",
              placeholder: "Select country"
            },
            carrier: {
                label: "Carrier",
                placeholder: "Select carrier"
            },
            product: {
                label: "Product",
                placeholder: "Select product",
                selectCarrierFirst: "Select carrier first"
            },
            service: {
                label: "Service",
                placeholder: "Select service",
                selectProductFirst: "Select product first"
            },
        },
        noServices: "No services selected",
      }
    }
  },
  general: {
    search: {
      placeholder: "Search"
    },
    filter: {
      title: "Filter"
    },
    export: {
      title: "Export"
    },
    import: {
      title: "Import",
      action: "Choose file",
      invalidFileType: "The file must be a file of type: xlsx, xls, csv.",
    },
    creation: {
      title: "Add new"
    },
    dropdownAll: "All", // TODO: [+nekear]
    nothingFound: {
      table: "NO RESULTS", // TODO: [+nekear]
      record: "NOTHING FOUND"
    },
    accessDenied: {
      main: "Access denied",
      visitRoles: "Visit <redirect>roles</redirect> page to grant access"
    },
    inDevelopment: "In development",
    components: {
      tax: {
        dropdown: {
          label: "Tax rate",
          placeholder: "Select tax rate",
          buttons: {
            create: "Create"
          }
        },
        modals: {
          creation: {
            heading: "Create tax",
            taxName: {
              label: "Tax name"
            },
            taxRate: {
              label: "Tax rate"
            },
            buttons: {
              create: "Create and apply"
            },
            responses: {
              success: "Tax has been created successfully!",
              error: "An error occurred while creating tax..."
            }
          }
        }
      },
      country: {
        dropdown: {
          label: "Country",
          placeholder: "Select country"
        }
      },
      currency: {
        dropdown: {
          label: "Currency",
          placeholder: "Select currency",
          buttons: {
            create: "Create"
          }
        },
        modals: {
          creation: {
            heading: "Create new currency",
            currencyCode: {
              label: "Currency code",
              placeholder: "Select currency code",
            },
            symbol: {
              label: "Symbol"
            },
            responses: {
              success: "Currency has been created successfully",
              error: "An error occurred while creating currency"
            },
            buttons: {
              create: "Create and apply"
            }
          }
        }
      },
      paymentTerms: {
        dropdown: {
          label: "Payment terms",
          placeholder: "Select payment terms",
        }
      }
    },
    modals: {
      export: {
        heading: "Export",
        fields: {
          records: {
            label: "Records",
            options: {
              all: "All",
              selected: "Selected"
            }
          },
          columns: {
            label: "Columns",
          },
          email: {
            label: "Email",
            placeholder: "Enter email address to send the export file",
          },
          amount: {
            label: "Export",
            options: {
              all: "All",
              first: "First",
              selected: "Selected"
            }
          }
        },
        concurrencyWarning: "You can only run one export at a time",
        filterInheritanceWarning: "Current filters and sorting will be applied",
        exportIsAlreadyInProgressAlert: "Another export is already in progress. Wait until it's finished.",
        buttons: {
          export: "Export"
        }
      }
    },
    validation: {
      global: {
        noSpaces: "Spaces are not allowed",
        nonNumeric: "Numbers are not allowed",
        domainName: "Invalid domain name",
        email: "Invalid email",
        alphaNumeric: "Field should contain at least one letter",
        minCharacters: "Must be {{min}} or more characters long",
        numericRequired: "Field should contain at least one number",
        imageFormats: "Supported image formats: jpeg, jpg, png, webp",
        fileSize: "Maximum file size is {{size}}",
        required: "Required",
        positive: "Must be equal or greater than 0",
        maxDecimalPrecision: "Maximum precision is {{maxPrecision}} digits",
        invalidDate: "Invalid date",
        boundedBy: "Must be between {{min}} and {{max}}",
        invalidRegistrationNumber: "Invalid company registration number format",
        compareDate: "End date must be greater than start date",
        noProductsAdded: "You have to add at least one product",
        invalidPhoneNumber: "Invalid phone number",
        invalidPhoneNumberLength: "Phone number is too short"
      },
      custom: {
        code: {
          unique: "Code has already been taken",
        }
      }
    },
    responses: {
      somethingWentWrong: "Something went wrong...",
    },
  },
  imports: {
    importModal: {
      heading: "Import",
      subheading: "Data matching",
      importIsAlreadyInProgressAlert: "Another import is already in progress. Wait until it's finished.",
      updateExisting: "Update existing records",
      validation: {
        requiredField: "Required field must have a selected header"
      },
      tips: {
        boolean: 'For boolean columns we will treat values as "true" if the value is "true" / "yes" / "on" / "1" and "false" otherwise',
        codeColumns: "Pay attention to columns that are expected to contain codes (marked with <badge><icon/></badge>). " +
            "Each value you supply should match a <b>code</b> <b>already</b> in your WMS. This connects your import to the right products, locations, or other items. If no match is found, you'll be notified via email",
      },
      responses: {
        success: "Import operation initiated..."
      },
      optionalFields: {
        hide: "Hide optional fields",
        show: "Show optional fields"
      },
      codeColumn: "Values in this column will be <b>matched</b> with <b>existing codes</b> to link records.",
      report_email: {
        label: "Send the report to email",
        placeholder: "Enter email address to send the report when import is finished",
        tip: "In case of failure, the report will be sent to this email address"
      },
      buttons: {
        changeFile: "Change file",
        import: "Import"
      },
    },
    modules: {
      tripletex: {
        states: {
          inProgress: "Importing data from Tripletex",
          imported: "Tripletex data has been successfully imported",
        },
        importModal: {
          heading: "Import data from Tripletex",
          importIsAlreadyInProgressAlert: "Another import is already in progress. Wait until it's finished.",
          whatWeImport: {
            label: "We will import:",
            customers: "Customers",
            products: "Products",
            suppliers: "Suppliers"
          },
          rules: {
            label: "Integration usage rules",
            bankAccount: "Invoicing functionality will be enabled only if a valid bank account is properly configured in Tripletex",
            customers: "Suppli will only import customers / suppliers with filled name, phone and email",
            sync: "Any updates made to products, customers and suppliers in either system will be automatically reflected in the other, maintaining data consistency",
          },
          locationDropdown: {
            label: "Put imported products to",
            placeholder: "Select location"
          },
          buttons: {
            import: "Import",
            cancel: "Cancel"
          },
        }
      },
      product: {
        states: {
          inProgress: "Importing products",
          imported: "Products have been successfully imported",
          error: {
            title: "An error occurred while importing products",
            message: "The report has been sent to your email"
          }
        },
        importModal: {
          heading: "Import products",
          tips: {
            location: "All location data can be omitted, if product is a service",
          },
          options: {
            name: "Name",
            productCode: "Product code",
            isService: "Is service",
            purchasePrice: "Purchase price",
            sellingPrice: "Selling price",
            defaultLocationCode: "Default location code",
            section: "Section code",
            locationQuantity: "Location quantity",
            minInventoryQuantity: "Min inventory quantity",
            categoryCode: "Category code",
            unitCode: "Unit code",
            supplierCode: "Supplier code",
            taxCode: "Tax code",
            collectionCode: "Collection code",
            currencyCode: "Currency code",
            barcode: "Barcode",
            hasRfid: "Has RFID",
            hasBatchNumber: "Has batch number",
            hasSerialNumber: "Has serial number",
            hasVariant: "Has variant",
            isComponent: "Is component",
            hasPackageUnit: "Has package unit",
            description: "Description",
            weight: "Weight",
            CBM: "CBM",
            width: "Width",
            height: "Height",
            length: "Length",
            minPurchaseQuantity: "Min purchase quantity",
            minSaleQuantity: "Min sale quantity",
            extraCost: "Extra cost",
          }
        }
      }
    }
  },
  exports: {
    modules: {
      product: {
        states: {
          inProgress: "Exporting products",
        }
      },
      stock: {
        states: {
          inProgress: "Exporting stock levels",
        }
      },
      transfer: {
        states: {
          inProgress: "Exporting transfers",
        }
      },
      sale_order: {
        states: {
          inProgress: "Exporting sale orders",
        }
      },
      purchase_order: {
        states: {
          inProgress: "Exporting purchase orders",
        }
      },
    }
  }
};

export default translationEN;
