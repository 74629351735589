import React from "react";
import { ReactComponent as ViewDetailsSVG } from "@assets/icons/ic_document_search.svg";
import { ReactComponent as EditSVG } from "@assets/icons/ic_edit.svg";
import { ReactComponent as TrashSVG } from "@assets/icons/ic_trash.svg";
import { ReactComponent as StartPickingSVG } from "@assets/icons/ic_start_picking.svg";
import { ReactComponent as PDFIcon } from "@assets/icons/ic_pdf.svg";
import { ReactComponent as PurchsaeOrderSVG } from "@assets/icons/ic_purchase.svg";
import { ReactComponent as SalesOrderSVG } from "@assets/icons/ic_salesOrder_cart.svg";
import { ReactComponent as StartReceivingSVG } from "@assets/icons/ic_start_receiving.svg";
import { ReactComponent as EmailSVG } from "@assets/icons/ic_email.svg";
import { ReactComponent as ProduceSVG } from "@assets/icons/ic_bom_produce.svg";
import { Barcode } from "lucide-react";


enum TinyActionIconType {
    Edit,
    Delete,
    ViewDetails,
    StartPicking,
    PDF,
    PurchaseOrder,
    SalesOrder,
    StartReceiving,
    Email,
    Produce,
    Barcode
    //Receive,
    //Duplicate
    // ... add other types if needed
}

interface BaseTinyActionIconProps {
    type: TinyActionIconType;
    onClick: () => void;
    variant?: 'gradient' | 'bordered'; // New prop for the variant
    disabled?: boolean;
    className?: string;
}

export default function BaseTinyActionIcon({type, onClick, variant = 'bordered', className, disabled}: BaseTinyActionIconProps) {
    // Style for gradient icons
    const gradientIconStyles = {
        className: "cursor-pointer hover:opacity-80"
    };

    // Style for bordered icons
    const borderedIconStyles = {
        width: "16px",
        height: "16px",
        className: "text-actionableIcons",
        cursor: "pointer",
    };

    // Determine which icon to render
    let iconElement;
    switch (type) {
        case TinyActionIconType.Edit:
            iconElement = <EditSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)} style={{transform: "scale(.8)"}}/>;
            break;
        case TinyActionIconType.Delete:
            iconElement =
                <TrashSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.ViewDetails:
            iconElement = <ViewDetailsSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.StartPicking:
            iconElement = <StartPickingSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.PDF:
            iconElement =
                <PDFIcon {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.PurchaseOrder:
            iconElement =
                <PurchsaeOrderSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.SalesOrder:
            iconElement =
                <SalesOrderSVG style={{fill: "#3C3769"}} {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.Email:
            iconElement =
                <EmailSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.StartReceiving:
            iconElement =
                <StartReceivingSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.Produce:
            iconElement =
                <ProduceSVG {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        case TinyActionIconType.Barcode:
            iconElement =
                <Barcode {...(variant === 'gradient' ? gradientIconStyles : borderedIconStyles)}/>;
            break;
        default:
            return null;
    }

    // Style for the icon container
    const iconContainerStyle = variant === 'gradient' ?
        {} : // Empty object for gradient style (no additional styling needed)
        {
            border: "1px solid transparent",
            background: "linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(43, 42, 53, 1), rgba(105, 89, 252, 1)) border-box",
            borderRadius: "6px",
            height: "32px",
            width: "32px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            opacity: disabled ? 0.5 : 1
        };

    return (
        <div style={iconContainerStyle} onClick={disabled ? undefined : onClick} className={className}>
            {iconElement}
        </div>
    );
}

export {BaseTinyActionIcon, TinyActionIconType};
